import {
    // Accordion,
    // AccordionDetails,
    // AccordionSummary,
    Box,
    // Checkbox,
    // FormGroup,
    // FormControlLabel as BaseFormControlLabel,
    // Drawer,
    // DrawerProps,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import { DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';
import { GridColDef } from '@mui/x-data-grid-pro';

import { AudienceSource, AudienceSourceCriteria } from '../../types/AudienceSource';
import { useAudienceSourceDataGridDefaultColumns } from './AudienceSourceRowDataGridCard';
import CDButton from '../CDButton';
import CDGrid, { CDGridProProps } from '../CDGridPro';
import Column from '../Column';
// import Row from '../Row';

export function DataGridNoRowsOverlay() {
    return (
        <Stack spacing={1} justifyContent="center" alignItems="center" sx={{ height: '70px' }}>
            <Typography variant="subtitle2">No Data Available</Typography>
        </Stack>
    );
}

export type AudienceSourceSelectionDataGridCardProps = {
    // $selection: any;
    rows: AudienceSource[];
    selections: AudienceSource[];
    onSelectionChange?: (selections: AudienceSource[]) => void;
    // selectedRows?: AudienceSource[];
    // onSelectionsChanged?: (items: AudienceSource[]) => void;
    searchCriteria: AudienceSourceCriteria;
    // changeSearchCriteria: (searchCriteria: AudienceSourceCriteria) => void;

    onDetachChange?: (selection: AudienceSource) => void;
    onDetachManyChange?: (selections: AudienceSource[]) => void;

    dataGridProps?: Omit<CDGridProProps<AudienceSource>, 'columns'>;
};

export default function AudienceSourceSelectionDataGridCard(props: AudienceSourceSelectionDataGridCardProps) {
    const { rows, selections } = props;
    // const { $selection, dataGridProps } = props;

    const { defaultColumns } = useAudienceSourceDataGridDefaultColumns({
        searchCriteria: props.searchCriteria,
    });

    const columns: GridColDef[] = [
        {
            headerName: '',
            field: 'Actions',
            width: 60,
            hideSortIcons: true,
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <IconButton
                        onClick={() => {
                            if (props.onDetachChange) {
                                props.onDetachChange(params.row);
                            }
                            // $selections.attach(params.row);

                            // let itemCount: number = selectedDealers.length + 1;
                            // let page: number = Math.floor(itemCount / 5);
                            // page = (page > 0 ? page : 1) + 1;

                            // setSelectedDealersPage(page);
                        }}
                    >
                        <DeleteOutlineIcon fontSize="small" color="error" />
                    </IconButton>
                );
            },
        },
        ...defaultColumns,
    ];
    return (
        <Column gap={0}>
            <Stack
                sx={{
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                    borderBottom: 'none',
                    borderTopRightRadius: (theme) => `${theme.shape.borderRadius}px`,
                    borderTopLeftRadius: (theme) => `${theme.shape.borderRadius}px`,
                }}
            >
                <Box sx={{ paddingX: 2, paddingY: 2 }}>
                    <Typography variant="subtitle1">Selected Audiences</Typography>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            visibility: rows.length > 0 ? 'visible' : 'hidden',
                        }}
                    >
                        <Typography component="span" color="text.primary" variant="subtitle2">
                            {selections.length}
                        </Typography>
                        <Typography component="span" color="text.secondary" variant="subtitle2">
                            /{rows.length} selected
                        </Typography>
                    </Typography>
                </Box>
                <Box sx={{ px: 2, pb: 1 }}>
                    <CDButton
                        variant="contained"
                        color="error"
                        size="small"
                        startIcon={<DeleteOutlineIcon />}
                        onClick={() => {
                            // let selected = $selections.selections.filter((_selection) =>
                            //     $selections.selections.some((selection) => $selections.getRowId(selection) === $selections.getRowId(_selection))
                            // );

                            // $selections.detachMany(selected);

                            if (props.onDetachManyChange) {
                                // let selected = selections.filter((_selection) =>
                                //     selections.some((selection) => $selections.getRowId(selection) === $selections.getRowId(_selection))
                                // );
                                props.onDetachManyChange(selections);
                            }
                        }}
                        sx={{
                            visibility: selections.length ? 'visible' : 'hidden',
                        }}
                    >
                        Bulk Remove
                    </CDButton>
                </Box>
            </Stack>
            <Box
                sx={{
                    height: rows.length > 0 ? 'auto' : '150px',
                    width: '100%',
                    flexGrow: 1,
                }}
            >
                <CDGrid
                    pageSize={10}
                    slots={{
                        noRowsOverlay: DataGridNoRowsOverlay,
                        toolbar: undefined,
                    }}
                    PaperProps={{
                        elevation: 0,
                    }}
                    {...props.dataGridProps}
                    checkboxSelection={true}
                    columns={columns}
                    rows={rows}
                    sx={{
                        '& .MuiDataGrid-main': {
                            mt: -0.5,
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            color: 'text.primary',
                        },
                        '& .MuiDataGrid-toolbarContainer': {
                            borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
                            borderRight: (theme) => `1px solid ${theme.palette.divider}`,
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
                            borderRight: (theme) => `1px solid ${theme.palette.divider}`,
                        },
                        '& [data-field="dailyReach"]': {
                            color: 'text.secondary',
                        },
                        '& [data-field="dailyImpressions"]': {
                            color: 'text.secondary',
                        },
                        '& [data-field="costType"]': {
                            color: 'text.secondary',
                        },
                    }}
                />
            </Box>
        </Column>
    );
}
