import { useMemo } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { Campaign } from '../../types/Campaign';
import Column from '../Column';

export interface CampaignAudienceTypeSummaryProps {
    campaign: Campaign;

    slotProps?: {
        typography?: TypographyProps;
    };
}

export default function CampaignAudienceTypeSummary(props: CampaignAudienceTypeSummaryProps) {
    const { campaign, slotProps } = props;

    const label = useMemo((): string => {
        switch (campaign.audienceType) {
            case 'AI':
                return 'AI Audience';

            case 'CUSTOM':
                return 'Custom Audience';
        }

        return campaign.audienceType as string;
    }, [campaign.audienceType]);

    return (
        <Column gap={0}>
            <Typography variant="body1" {...slotProps?.typography}>
                {label}
            </Typography>
        </Column>
    );
}
