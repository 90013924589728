import { useEffect } from 'react';

import { Campaign } from '../../../../types/Campaign';
import CampaignTargetingAudienceCard from './CampaignTargetingAudienceCard';
// import CampaignTargetingDeviceCard from './CampaignTargetingDeviceCard';
// import CampaignTargetingLocationCard from './CampaignTargetingLocationCard';
import CampaignTargetingLocationDrawerCard from './CampaignTargetingLocationDrawerCard';
import Column from '../../../../components/Column';

export type CampaignWizardGeographyStepProps = {
    campaign: Campaign;
    onChange(value: Partial<Campaign>): void;
};

export default function CampaignWizardGeographyStep(props: CampaignWizardGeographyStepProps) {
    const { campaign, onChange } = props;

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, []);

    return (
        <Column gap={3}>
            {/*<CampaignTargetingLocationCard campaign={campaign} onChange={onChange} />*/}

            {/*<CampaignTargetingDeviceCard campaign={campaign} onChange={onChange} />*/}

            <CampaignTargetingAudienceCard campaign={campaign} onChange={onChange} />

            <CampaignTargetingLocationDrawerCard campaign={campaign} onChange={onChange} />
        </Column>
    );
}
