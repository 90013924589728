import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import {
    Checkbox,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    Radio,
    RadioGroup,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@mui/icons-material';
import { Card, CardContent, CardHeader, FormLabel, FormControlLabel } from './CampaignWizardStyles';
import { Percent as PercentIcon } from '@mui/icons-material';

import { CampaignWizardStepProps } from './CampaignWizard';
import { CDTextField } from '../../../../components/CDTextField';
import { Code } from '../../../../types/Code';
import { useCampaignWizardContext } from '../../../../hooks/useCampaignWizard';
import CampaignBidStrategyRadioGroup from '../CampaignBidStrategyRadioGroup';
import CampaignGoalTypeRadioGroup from '../CampaignGoalTypeRadioGroup';
import CampaignKPIRadioGroup from '../CampaignKPIRadioGroup';
import Column from '../../../../components/Column';
import Row from '../../../../components/Row';

type CampaignSetupOptimizationCardProps = {} & CampaignWizardStepProps;

export default function CampaignSetupOptimizationCard(props: CampaignSetupOptimizationCardProps) {
    const { campaign, onChange } = props;
    const { $campaignDefaultSettings, hasError, isDraftCampaign, isNewCampaign } = useCampaignWizardContext();
    const { campaignDefaultSettingCriteria = {}, setCampaignDefaultSettingCriteria } = $campaignDefaultSettings || {};

    const campaignTargetKpi = useMemo(() => {
        if (campaign.targetKpi === -1) {
            return '';
        }
        if (campaign.targetKpi) {
            return campaign.targetKpi * 100;
        }
        return '';
    }, [campaign.targetKpi]);

    const campaignTargetKpiRequired = useMemo(() => {
        if (campaign.bidStrategy === 'PRIORITIZE_KPI_TARGET') {
            return !['Awareness'].includes(campaign.goal as string);
        }
        return campaign.targetKpiDisabled === false;
    }, [campaign.bidStrategy, campaign.goal, campaign.targetKpiDisabled]);

    const campaignTargetKpiDisabled = useMemo(() => {
        if (campaign.bidStrategy === 'PRIORITIZE_KPI_TARGET') {
            return false;
            // return ['Awareness'].includes(campaign.goal as string);
        }
        return campaign.targetKpiDisabled === true;
    }, [campaign.bidStrategy, campaign.targetKpiDisabled]);

    const campaignTargetKpiDisabledChecked = useMemo(() => {
        if (campaign.bidStrategy === 'PRIORITIZE_KPI_TARGET') {
            if (['Consideration', 'Conversion'].includes(campaign.goal as string)) {
                return false;
            }
        }
        return campaign.targetKpiDisabled ?? false;
    }, [campaign.bidStrategy, campaign.goal, campaign.targetKpiDisabled]);

    const campaignTargetKpiEnabled = useMemo(() => {
        if (campaign.bidStrategy === 'PRIORITIZE_KPI_TARGET') {
            return !['Awareness'].includes(campaign.goal as string);
        }
        return false;
    }, [campaign.bidStrategy, campaign.goal]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.name) {
            if (event.target.type === 'checkbox') {
                props.onChange({
                    [event.target.name]: event.target.checked,
                });
            } else {
                switch (event.target.name) {
                    case 'automateBudgetAllocation':
                        props.onChange({
                            [event.target.name]: event.target.value === '1',
                        });
                        break;

                    case 'goal':
                        if (setCampaignDefaultSettingCriteria && campaignDefaultSettingCriteria) {
                            setCampaignDefaultSettingCriteria({
                                ...campaignDefaultSettingCriteria,
                                isDirty: true,
                                mediaType: campaign.mediaType as string,
                                goalType: event.target.value as string,
                            });
                        }

                        props.onChange({
                            [event.target.name]: event.target.value,
                            kpi: '',
                        });
                        break;

                    case 'targetKpi':
                        props.onChange({
                            [event.target.name]: (event.target.value as any as number) / 100,
                        });
                        break;

                    default:
                        props.onChange({
                            [event.target.name]: event.target.value,
                        });
                        break;
                }
            }
        }
    };

    const filterBidStrategies = useCallback(
        (codes: Code[]): Code[] => {
            let includedCodes: string[] = ['SPEND_BUDGET_IN_FULL', 'PRIORITIZE_KPI_TARGET'];

            if (campaign.goal === 'Awareness') {
                includedCodes = ['SPEND_BUDGET_IN_FULL'];
            }

            return codes.filter((code: Code) => includedCodes.includes(code.code));
        },
        [campaign.goal]
    );

    useEffect(() => {
        let defaultKpiType: string = '';

        switch (`${campaign.mediaType}|${campaign.goal}`) {
            case 'Display|Awareness':
                defaultKpiType = 'REACH';
                break;
            case 'Display|Consideration':
                defaultKpiType = 'CLICK_THROUGH_RATE';
                break;
            case 'Display|Conversions':
                defaultKpiType = 'COST_PER_ACTION';
                break;
            case 'Video|Awareness':
                defaultKpiType = 'REACH';
                break;
            case 'Video|Consideration':
                defaultKpiType = 'CLICK_THROUGH_RATE';
                break;
            case 'Audio|Awareness':
                defaultKpiType = 'REACH';
                break;
        }

        if (defaultKpiType) {
            onChange({
                kpi: defaultKpiType,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign.mediaType, campaign.goal]);

    return (
        <Card id="campaign-wizard-setup-optimization" variant="outlined">
            <CardHeader
                title={
                    <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="body1" color="text.primary">
                            Goal & Optimization
                        </Typography>
                    </Stack>
                }
            />
            <CardContent>
                <Column>
                    <FormControl error={hasError('goal')}>
                        <FormLabel required={true} sx={{ mb: 2 }}>
                            Select a Campaign Goal
                        </FormLabel>

                        <CampaignGoalTypeRadioGroup
                            name="goal"
                            value={campaign.goal ?? ''}
                            onChange={handleChange}
                            disabled={!isNewCampaign && !isDraftCampaign}
                            filter={(codes: Code[]): Code[] => {
                                return codes.filter((code: Code) => {
                                    switch (campaign.mediaType) {
                                        case 'Audio':
                                            return ['Awareness'].includes(code.code);

                                        case 'Video':
                                            return ['Awareness', 'Consideration'].includes(code.code);

                                        case 'Streaming TV':
                                            return ['Awareness'].includes(code.code);

                                        case 'Online Video':
                                            return ['Consideration'].includes(code.code);
                                    }

                                    return true;
                                });
                            }}
                        />

                        {hasError('goal') && (
                            <FormHelperText error={true}>Campaign Goal selection is required</FormHelperText>
                        )}
                    </FormControl>

                    {false && (
                        <FormControl error={hasError('kpi')}>
                            <FormLabel required={true} sx={{ mb: 2 }}>
                                Select a KPI
                            </FormLabel>

                            <CampaignKPIRadioGroup
                                name="kpi"
                                value={campaign.kpi ?? ''}
                                onChange={handleChange}
                                disabled={!isNewCampaign && !isDraftCampaign}
                                filter={(codes: Code[]): Code[] => {
                                    let includedCodes: string[] = [];

                                    switch (`${campaign.mediaType}|${campaign.goal}`) {
                                        case 'Display|Awareness':
                                            includedCodes = ['REACH'];
                                            break;

                                        case 'Display|Consideration':
                                            includedCodes = [
                                                'CLICK_THROUGH_RATE',
                                                'COST_PER_CLICK',
                                                'COST_PER_DETAIL_PAGE_VIEW',
                                                'DETAIL_PAGE_VIEW_RATE',
                                            ];
                                            break;
                                        case 'Display|Conversions':
                                            includedCodes = ['COST_PER_ACTION'];
                                            break;
                                        case 'Video|Awareness':
                                        case 'Streaming TV|Awareness':
                                            includedCodes = ['REACH'];
                                            break;
                                        case 'Video|Consideration':
                                        case 'Online Video|Consideration':
                                            includedCodes = [
                                                'CLICK_THROUGH_RATE',
                                                'COST_PER_VIDEO_COMPLETION',
                                                'VIDEO_COMPLETION_RATE',
                                            ];
                                            break;
                                        case 'Audio|Awareness':
                                            includedCodes = ['REACH'];
                                            break;
                                    }

                                    if (includedCodes.length > 0) {
                                        return includedCodes
                                            .map((rawCode: string) => {
                                                const code: Code | undefined = codes.find(
                                                    (_code: Code) => _code.code === rawCode
                                                );

                                                if (code) {
                                                    return code;
                                                }

                                                return {
                                                    id: 0,
                                                    codeGroup: '',
                                                    code: '',
                                                    description: '',
                                                    defaultOption: false,
                                                    sortOrder: 0,
                                                };
                                            })
                                            .filter((code: Code) => code.id > 0);
                                    }

                                    return codes;
                                }}
                            />

                            {hasError('kpi') && (
                                <FormHelperText error={true}>Campaign KPI selection is required</FormHelperText>
                            )}
                        </FormControl>
                    )}

                    {false && (
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <FormControl error={hasError('targetKpi')} fullWidth size="small">
                                    <CDTextField
                                        variant="outlined"
                                        type="number"
                                        label="KPI Target"
                                        name="targetKpi"
                                        error={hasError('targetKpi')}
                                        required={campaignTargetKpiRequired}
                                        disabled={campaignTargetKpiDisabled}
                                        value={campaignTargetKpi}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <PercentIcon
                                                        fontSize="small"
                                                        // color="action"
                                                        sx={{
                                                            color: (theme) => theme.palette.action.disabled,
                                                        }}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                        inputProps={{
                                            min: 0.01,
                                            max: 100,
                                        }}
                                        onChange={handleChange}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>

                                <FormControl error={hasError('targetKpiDisabled')} size="small" sx={{ mt: 1 }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                name="targetKpiDisabled"
                                                onChange={handleChange}
                                                checked={campaignTargetKpiDisabledChecked}
                                                disabled={campaignTargetKpiEnabled}
                                            />
                                        }
                                        label="Don't set a KPI target"
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                color: (theme) => theme.palette.text.secondary,
                                                fontSize: '14px',
                                            },
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    )}

                    {false && (
                        <>
                            <Divider />

                            <Typography variant="subtitle2" color="text.primary">
                                Select a Optimization Strategy
                            </Typography>

                            <FormControl>
                                <Row gap={1} alignItems="center" sx={{ mb: 2 }}>
                                    <FormLabel required={true}>Bidding Priority</FormLabel>

                                    <Tooltip title="Switching bidding priority may impact performance and delivery. Allow a week for new bidding priority to adapt before evaluating.">
                                        <HelpOutlineOutlinedIcon color="action" sx={{ fontSize: 16 }} />
                                    </Tooltip>
                                </Row>

                                <CampaignBidStrategyRadioGroup
                                    name="bidStrategy"
                                    value={campaign.bidStrategy ?? ''}
                                    onChange={handleChange}
                                    filter={filterBidStrategies}
                                />

                                {hasError('bidStrategy') && (
                                    <FormHelperText error={true}>
                                        Optimization Strategy selection is required
                                    </FormHelperText>
                                )}
                            </FormControl>

                            <FormControl>
                                <Row gap={1} alignItems="center" sx={{ mb: 2 }}>
                                    <FormLabel required={true}>Budget Management Strategy</FormLabel>

                                    <Tooltip title="Automated Budget Allocation will automatically adjust your budget towards better performing line items based on your KPI selection. Select Manage Budget Manually if your line item budgets cannot be shifted to better performing line items.">
                                        <HelpOutlineOutlinedIcon color="action" sx={{ fontSize: 16 }} />
                                    </Tooltip>
                                </Row>

                                <RadioGroup
                                    name="automateBudgetAllocation"
                                    value={campaign.automateBudgetAllocation ? '1' : '0'}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel
                                        value="1"
                                        control={<Radio />}
                                        label="Automate budget allocation"
                                    />
                                    <FormControlLabel value="0" control={<Radio />} label="Manage budget manually" />
                                </RadioGroup>
                            </FormControl>
                        </>
                    )}
                </Column>
            </CardContent>
        </Card>
    );
}
