import { useEffect, useMemo, useState } from 'react';
import { GridPaginationModel, GridRowSelectionModel } from '@mui/x-data-grid-pro';

export interface DataGridSelectionProps<T> {
    getRowId?: (row: T) => any;
}

// useDataGridRowSelection
export function useDataGridSelection<T>(props?: DataGridSelectionProps<T>) {
    const [rows, setRows] = useState<T[]>([]);
    const [selections, setSelections] = useState<T[]>([]);
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 10,
    });

    const getRowId = (row: T): any => {
        if (props?.getRowId) {
            return props.getRowId(row) as any;
        }

        return (row as any)?.id;
    };

    const isRowEqualToSelection = (row: T, selection: T): boolean => {
        return getRowId(row) === getRowId(selection);
    };

    const isRowNotEqualToSelection = (row: T, selection: T): boolean => {
        return getRowId(row) !== getRowId(selection);
    };

    const handlePaginationModelChange = (model: GridPaginationModel): void => {
        setPaginationModel(model);
    };

    const handleRowSelectionModelChange = (model: GridRowSelectionModel): void => {
        setRowSelectionModel(model);
    };

    const isRowEmpty = useMemo((): boolean => {
        return rows.length <= 0;
    }, [rows]);

    const isRowNotEmpty = useMemo((): boolean => {
        return rows.length > 0;
    }, [rows]);

    const isSelectionEmpty = useMemo((): boolean => {
        return selections.length <= 0;
    }, [selections]);

    const isSelectionNotEmpty = useMemo((): boolean => {
        return selections.length > 0;
    }, [selections]);

    const attach = (newRow: T): void => {
        setRows((newRows: T[]) => {
            if (!newRows.some((row: T) => isRowEqualToSelection(row, newRow))) {
                return [...newRows, newRow];
            }
            return newRows;
        });
    };

    const attachMany = (newRows: T[]): void => {
        setRows((currentRows: T[]) => {
            const uniqueRows = newRows.filter(
                (newRow: T) => !currentRows.some((row: T) => isRowEqualToSelection(row, newRow))
            );

            return [...currentRows, ...uniqueRows];
        });
    };

    const detach = (_row: T): void => {
        setRows((currentRows: T[]) => currentRows.filter((row: T) => isRowNotEqualToSelection(row, _row)));
    };

    const detachMany = (_rows: T[]): void => {
        setRows((currentRows: T[]) =>
            currentRows.filter((row: T) => !_rows.some((_row: T) => isRowEqualToSelection(_row, row)))
        );
    };

    const select = (newSelection: T): void => {
        setSelections((newSelections: T[]) => {
            if (!newSelections.some((selection: T) => isRowEqualToSelection(selection, newSelection))) {
                return [...newSelections, newSelection];
            }
            return newSelections;
        });
    };

    const selectMany = (newSelections: T[]): void => {
        setSelections((currentSelections: T[]) => {
            const uniqueSelections = newSelections.filter(
                (newSelection: T) =>
                    !currentSelections.some((selection: T) => isRowEqualToSelection(selection, newSelection))
            );

            return [...currentSelections, ...uniqueSelections];
        });
    };

    const deselect = (_selection: T): void => {
        setSelections((currentSelections: T[]) =>
            currentSelections.filter((selection: T) => isRowNotEqualToSelection(selection, _selection))
        );
    };

    const deselectMany = (_selections: T[]): void => {
        setSelections((currentSelections: T[]) =>
            currentSelections.filter(
                (selection: T) => !_selections.some((_selection: T) => isRowEqualToSelection(_selection, selection))
            )
        );
    };

    useEffect(() => {
        const newSelections: T[] = rows.filter((selection: T) => rowSelectionModel.indexOf(getRowId(selection)) > -1);
        setSelections(newSelections);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowSelectionModel]);

    return {
        paginationModel,
        setPaginationModel,
        handlePaginationModelChange,

        rowSelectionModel,
        setRowSelectionModel,
        handleRowSelectionModelChange,

        getRowId,
        isRowEqualToSelection,
        isRowNotEqualToSelection,

        rows,
        setRows,
        isRowEmpty,
        isRowNotEmpty,

        selections,
        setSelections,
        isSelectionEmpty,
        isSelectionNotEmpty,

        attach,
        attachMany,
        detach,
        detachMany,

        select,
        selectMany,
        deselect,
        deselectMany,
    };
}
