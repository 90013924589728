import { useCallback, useContext, useEffect, useState } from 'react';
import { Box, FormControl, Stack, Typography } from '@mui/material';
import { Card, CardContent, CardHeader, FormLabel } from './CampaignWizardStyles';

import { CampaignWizardStepProps } from './CampaignWizard';
import { InventorySource, InventorySourceCriteria } from '../../../../types/Inventory';
import { toCampaignAdGroupInventorySources } from '../../../../hooks/useInventorySources';
import { useCampaignWizardContext } from '../../../../hooks/useCampaignWizard';
import { UserContext } from '../../../../App';
import Column from '../../../../components/Column';
import InventorySourceField from '../../../../components/Inventory/InventorySourceField';
import Row from '../../../../components/Row';

type CampaignSetupInventoryCardProps = {} & CampaignWizardStepProps;

export default function CampaignSetupInventoryCard(props: CampaignSetupInventoryCardProps) {
    const { campaign, onChange } = props;
    const { userContext } = useContext(UserContext);
    const { $campaignDefaultSettings, $loader, $inventorySources, isDraftCampaign, isNewCampaign } =
        useCampaignWizardContext();
    const {
        inventorySources: selections = [],
        fetchInventorySources: fetchSelections = () => {},
        isFetchingInventorySources: isFetchingSelections = false,
        hydrateInventorySources: hydrateSelections = (selection: InventorySource[]) => [],
    } = $inventorySources || {};

    const { campaignDefaultSettings } = $campaignDefaultSettings || {};

    const [inventorySources, setInventorySources] = useState<InventorySource[]>([]);
    const [searchCriteria, setSearchCriteria] = useState<InventorySourceCriteria>({
        searchText: '',
        mediaType: '',
        inventorySourceTypes: [],
    });

    function changeSearchCriteria(searchCriteria: InventorySourceCriteria) {
        setSearchCriteria({ ...searchCriteria });
    }

    const updateInventorySources = useCallback(
        (newInventorySources: InventorySource[]) => {
            setInventorySources(newInventorySources);

            if (onChange) {
                if (campaign?.adGroups?.length) {
                    campaign.adGroups[0] = {
                        ...campaign.adGroups[0],
                        adGroupInventorySources: toCampaignAdGroupInventorySources(
                            newInventorySources,
                            campaign.adGroups[0].adGroupInventorySources ?? []
                        ),
                    };
                } else {
                    campaign.adGroups.push({
                        adGroupInventorySources: toCampaignAdGroupInventorySources(newInventorySources),
                    });
                }

                onChange({
                    adGroups: campaign.adGroups,
                });
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [onChange, campaign]
    );

    const handleInventorySourecChange = (newInventorySources: InventorySource[]): void => {
        updateInventorySources(newInventorySources);
    };

    useEffect(() => {
        if ($loader) {
            if (isFetchingSelections) {
                $loader.loading('fetchInventorySources');
            } else {
                $loader.loaded('fetchInventorySources');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetchingSelections]);

    useEffect(() => {
        setSearchCriteria({
            ...searchCriteria,
            mediaType: campaign.mediaType as string,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign.mediaType]);

    useEffect(() => {
        if (campaign?.adGroups?.length) {
            if ($inventorySources) {
                setInventorySources($inventorySources.getCampaignInventorySources(campaign));
            }
        } else {
            setInventorySources([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign.adGroups]);

    useEffect(() => {
        if ($campaignDefaultSettings && campaignDefaultSettings?.length) {
            if (campaign?.adGroups?.length) {
                campaign.adGroups[0] = {
                    ...campaign.adGroups[0],
                    adGroupInventorySources: $campaignDefaultSettings.toCampaignAdGroupInventorySources([]),
                };
            } else {
                campaign.adGroups.push({
                    adGroupInventorySources: $campaignDefaultSettings.toCampaignAdGroupInventorySources([]),
                });
            }

            if ($inventorySources) {
                updateInventorySources($inventorySources.getCampaignInventorySources(campaign));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignDefaultSettings]);

    return (
        <Card
            id="campaign-wizard-setup-inventory"
            variant="outlined"
            sx={{ display: userContext.isAdmin() ? 'block' : 'none' }}
        >
            <CardHeader
                title={
                    <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="body1" color="text.primary">
                            Inventory
                        </Typography>
                    </Stack>
                }
            />
            <CardContent>
                <Column>
                    <Row>
                        <FormControl>
                            <FormLabel required={true} sx={{ mb: 2 }}>
                                Inventory Sources
                            </FormLabel>

                            <Box>
                                <InventorySourceField
                                    searchCriteria={searchCriteria}
                                    changeSearchCriteria={changeSearchCriteria}
                                    selections={selections}
                                    fetchSelections={fetchSelections}
                                    hydrateSelections={hydrateSelections}
                                    onChange={handleInventorySourecChange}
                                    items={inventorySources}
                                    buttonProps={{
                                        disabled: !campaign.dealer || !(isNewCampaign || isDraftCampaign),
                                        tooltip: !campaign.dealer ? 'You must select an Advertiser.' : '',
                                    }}
                                />
                            </Box>
                        </FormControl>
                    </Row>
                </Column>
            </CardContent>
        </Card>
    );
}
