import { useCallback, useRef, useState } from 'react';
import { CpmValue } from '../types/Campaign';
import { useSingleLoader } from './useSingleLoader';
import ApiService from '../ApiService';

export function useCampaignCpmValues() {
    const $loader = useSingleLoader();
    const {
        isLoading: isFetchingCampaignCpmValues,
        setIsLoading: setIsFetchingCampaignCpmValues,

        isLoaded: isFetchedCampaignCpmValues,
        setIsLoaded: setIsFetchedCampaignCpmValues,
    } = $loader;

    const $cpmValues = useRef<CpmValue[]>([]);
    const [cpmValues, setCpmValues] = useState<CpmValue[]>([]);
    const [cpmValue, setCpmValue] = useState<CpmValue>({ mediaType: '', cpmValue: 0, fixedCpm: false });

    const syncCpmValues = (newValues: CpmValue[]) => {
        $cpmValues.current = newValues;
        setCpmValues(newValues);
    };

    const fetchCampaignCpmValues = useCallback((dealerId: number, mediaType: string = '') => {
        $loader.withLoader(async () => {
            try {
                const response = await ApiService.getCampaignCPMValues(dealerId);
                const newValues = response.data;

                syncCpmValues(newValues);

                if (mediaType) {
                    setCpmValue(identifyCpmValue(mediaType, newValues));
                }
            } catch (error) {
                // no-op
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const identifyCpmValue = (mediaType: string, values: CpmValue[] = $cpmValues.current): CpmValue => {
        return (
            values.find((cpmValue) => cpmValue.mediaType === mediaType.replace(' ', '')) || {
                mediaType: '',
                cpmValue: 0,
                fixedCpm: false,
            }
        );
    };

    return {
        fetchCampaignCpmValues,
        cpmValues,
        setCpmValues: syncCpmValues,

        cpmValue,
        setCpmValue,

        identifyCpmValue,

        isFetchingCampaignCpmValues,
        setIsFetchingCampaignCpmValues,

        isFetchedCampaignCpmValues,
        setIsFetchedCampaignCpmValues,
    };
}
