import { Box } from '@mui/material';
import { styled } from '@mui/material';

const FormSection = styled(Box)(({ theme }) => ({
    padding: '15px',
    borderRadius: '6px',
    border: '1px solid #e0e0e0',
    position: 'relative',
    '& h6': {
        color: '#777',
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: '13px',
        position: 'absolute',
        top: '-11px',
        background: '#fff',
        left: '6px',
        padding: '0 5px',
        backgroundColor: '#fff',
    },
}));

export default FormSection;
