import { useEffect, useMemo } from 'react';
import { Typography } from '@mui/material';
import { Campaign } from '../../types/Campaign';
import { ProductCategory } from '../../components/ProductCategoryAutocomplete';
import Column from '../Column';

import useProductCategories from '../../hooks/useProductCategories';

export interface CampaignProductCategorySummaryProps {
    campaign: Campaign;
}

export default function CampaignProductCategorySummary(props: CampaignProductCategorySummaryProps) {
    const { campaign } = props;

    const { fetchProductCategories, productCategories, getCampaignProductCategories } = useProductCategories({
        dealer: campaign.dealer,
    });

    const label = useMemo((): string => {
        const campaignProductCategories: ProductCategory[] = getCampaignProductCategories(campaign);

        if (campaignProductCategories.length > 0) {
            const parentProductCategory: ProductCategory | undefined = productCategories.find(
                (object: ProductCategory) => object.id === campaignProductCategories[0].parentId
            );

            if (parentProductCategory) {
                return parentProductCategory.name;
            }
        }

        return ' - ';
    }, [campaign.adGroups, productCategories]);

    useEffect(() => {
        fetchProductCategories();
    }, [fetchProductCategories]);

    return (
        <Column gap={0}>
            <Typography variant="body1">{label}</Typography>
        </Column>
    );
}
