import { ReactNode } from 'react';
import { IconButton, List, ListItem, ListProps, ListItemText } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { Location } from '../../types/Location';

export type LocationListProps = {
    items: Location[];
    onChange?: (items: Location[]) => void;
    onDetach?: (item: Location) => void;
    getItemKey?: (item: Location) => string;
    getItemLabel?: (item: Location) => ReactNode;
} & ListProps;

export default function LocationList(props: LocationListProps) {
    const {
        items,
        onChange,
        onDetach,
        getItemKey = (item: Location): string => {
            return item.id;
        },
        getItemLabel = (item: Location): ReactNode => {
            return item?.name;
        },
        ...listProps
    } = props;

    return (
        <List {...listProps}>
            {items.map((item: Location) => (
                <ListItem
                    key={getItemKey(item)}
                    dense
                    disableGutters={false}
                    divider
                    secondaryAction={
                        onDetach && (
                            <IconButton
                                size="small"
                                aria-label="detach item"
                                onClick={() => {
                                    if (onDetach) {
                                        onDetach(item);
                                    }
                                }}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        )
                    }
                    sx={{ py: 1 }}
                >
                    <ListItemText primary={getItemLabel(item)} />
                </ListItem>
            ))}
        </List>
    );
}
