import { ChangeEvent } from 'react';
import { IconButton, InputAdornment, MenuItem } from '@mui/material';
import { ClearOutlined as ClearOutlinedIcon, Search as SearchIcon } from '@mui/icons-material';

import { AudienceSourceCriteria } from '../../types/AudienceSource';
import { CDTextField } from '../CDTextField';
import Row from '../Row';

export type AudienceSourceCriteriaSearchProps = {
    searchCriteria: AudienceSourceCriteria;
    changeSearchCriteria: (searchCriteria: AudienceSourceCriteria) => void;
};

export default function AudienceSourceCriteriaSearch(props: AudienceSourceCriteriaSearchProps) {
    const { searchCriteria, changeSearchCriteria } = props;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        changeSearchCriteria({
            ...searchCriteria,
            [event.target.name]: event.target.value,
        });
    };

    return (
        <Row sx={{ padding: 2 }}>
            <CDTextField
                sx={{ width: '300px' }}
                id="searchText"
                name="searchText"
                value={searchCriteria.searchText}
                autoComplete="off"
                size="small"
                label="Search Audiences"
                onKeyDown={(event) => {
                    if (['Enter', 'NumpadEnter'].includes(event.code) === true) {
                        // search();
                    }
                }}
                onChange={handleChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon color="action" />
                        </InputAdornment>
                    ),
                    endAdornment: searchCriteria.searchText ? (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => {
                                    changeSearchCriteria({
                                        ...searchCriteria,
                                        searchText: '',
                                    });
                                }}
                                edge="end"
                            >
                                <ClearOutlinedIcon fontSize="small" color="action" />
                            </IconButton>
                        </InputAdornment>
                    ) : (
                        ''
                    ),
                }}
            />
            <CDTextField
                name="costType"
                label="Cost Type"
                variant="outlined"
                select
                SelectProps={{
                    native: false,
                    displayEmpty: true,
                }}
                shrinkLabel
                value={searchCriteria.costType}
                onChange={handleChange}
                sx={{ flexBasis: '100px' }}
            >
                <MenuItem value="">Any</MenuItem>
                <MenuItem value="Free">Free</MenuItem>
                <MenuItem value="Paid">Paid</MenuItem>
            </CDTextField>
        </Row>
    );
}
