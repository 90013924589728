import { useCallback, useEffect, useRef, useState } from 'react';

import { Campaign, CampaignAdGroupInventorySource } from '../types/Campaign';
import { Dealer } from '../types/Dealer';
import { DspInventorySourceDeal } from '../types/DspInventorySourceDeal';
import { DspInventorySourceExchange } from '../types/DspInventorySourceExchange';
import { InventorySource } from '../types/Inventory';
import ApiService from '../ApiService';

export function toCampaignAdGroupInventorySources(
    inventorySources: InventorySource[],
    adGroupInventorySources: CampaignAdGroupInventorySource[] = []
): CampaignAdGroupInventorySource[] {
    return [
        ...adGroupInventorySources
            .filter((x: CampaignAdGroupInventorySource) => {
                return inventorySources.find((y: InventorySource) => x.dspInventoryId === y.id) !== undefined;
            })
            .map((x: CampaignAdGroupInventorySource) => {
                const y: InventorySource | undefined = inventorySources.find(
                    (y: InventorySource) => x.dspInventoryId === y.id
                );
                if (y !== undefined) {
                    x.inventorySourceType = y.inventorySourceType;
                }
                return x;
            }),
        ...inventorySources
            .filter((x: InventorySource) => {
                return (
                    adGroupInventorySources.find((y: CampaignAdGroupInventorySource) => x.id === y.dspInventoryId) ===
                    undefined
                );
            })
            .map((inventorySource: InventorySource) => {
                return {
                    dspInventoryId: inventorySource.id,
                    inventorySourceType: inventorySource.inventorySourceType as string,
                };
            }),
    ];
}

export function toInventorySources(adGroupInventorySources: CampaignAdGroupInventorySource[]): InventorySource[] {
    let inventorySources: InventorySource[] = [];

    if (adGroupInventorySources.length > 0) {
        inventorySources.push(
            ...adGroupInventorySources.map((adGroupInventorySource: CampaignAdGroupInventorySource) => {
                return {
                    id: adGroupInventorySource.dspInventoryId,
                    deviceType: '',
                    inventorySourceType: adGroupInventorySource.inventorySourceType as string,
                    inventoryType: '',
                    name: '',
                };
            })
        );
    }

    return inventorySources;
}

export function toDspInventorySourceExchangeDTO(
    dspInventorySourceExchange: DspInventorySourceExchange
): InventorySource {
    let inventorySourceDTO: InventorySource = {
        id: dspInventorySourceExchange.dspInventoryId,
        deviceType: dspInventorySourceExchange.dspDeviceType,
        inventorySourceType: dspInventorySourceExchange.dspInventorySourceType,
        inventoryType: dspInventorySourceExchange.dspInventoryType,
        name: dspInventorySourceExchange.dspName,
    };

    if (inventorySourceDTO?.inventorySourceType === 'AMAZON_PUBLISHER_DIRECT') {
        inventorySourceDTO.inventorySourceType = 'APD';
    }

    return inventorySourceDTO;
}

export function toDspInventorySourceDealDTO(dspInventorySourceDeal: DspInventorySourceDeal): InventorySource {
    const inventorySourceDTO: InventorySource = {
        id: dspInventorySourceDeal.dspId,
        name: dspInventorySourceDeal.name,
        inventoryType: dspInventorySourceDeal.inventoryType,
        inventorySourceType: 'DEAL',
        deviceType: '',
        deal: {
            id: dspInventorySourceDeal.dspId,
            name: dspInventorySourceDeal.name,
            inventoryType: dspInventorySourceDeal.inventoryType,
            dealStatus: dspInventorySourceDeal.dealStatus,
            marketplaceDeal: dspInventorySourceDeal.marketplaceDeal as any as string,
            recommendedDeal: dspInventorySourceDeal.recommendedDeal,
            externalDealId: dspInventorySourceDeal.externalDealId as any as number,
            dealType: dspInventorySourceDeal.dealType,
            publisherName: dspInventorySourceDeal.publisherName,
            startDateTime: dspInventorySourceDeal.startDateTime,
            endDateTime: dspInventorySourceDeal.endDateTime,
            exchangeId: dspInventorySourceDeal.exchangeId as any as number,

            dealPrice: dspInventorySourceDeal.dealPriceCurrency
                ? {
                      amount: dspInventorySourceDeal.dealPriceAmount,
                      currency: dspInventorySourceDeal.dealPriceCurrency,
                      priceType: dspInventorySourceDeal.dealPriceType,
                  }
                : null,
            dealBudget: dspInventorySourceDeal.dealBudgetCurrency
                ? {
                      amount: dspInventorySourceDeal.dealBudgetAmount,
                      currency: dspInventorySourceDeal.dealBudgetCurrency,
                  }
                : null,
        },
    };

    return inventorySourceDTO;
}

export interface InventorySources {
    //
    inventorySources: InventorySource[];
    setInventorySources(inventorySources: InventorySource[]): void;
    fetchInventorySources(): void;
    isFetchingInventorySources: boolean;
    setIsFetchingInventorySources(isFetchingInventorySources: boolean): void;

    //
    getInventorySourceName(inventorySource: InventorySource): string;
    getCampaignInventorySources(campaign: Campaign): InventorySource[];

    //
    hydrateInventorySource(unhydratedInventorySource: InventorySource): InventorySource;
    hydrateInventorySources(unhydratedInventorySources: InventorySource[]): InventorySource[];
}

type InventorySourcesProps = {
    dealer: Dealer | null | undefined;
};

export default function useInventorySources(props: InventorySourcesProps): InventorySources {
    const { dealer } = props;
    const [inventorySources, setInventorySources] = useState<InventorySource[]>([]);
    const [isFetchingInventorySources, setIsFetchingInventorySources] = useState<boolean>(false);
    const isFetched = useRef<boolean>(false);

    const fetchInventorySources = useCallback(async () => {
        if (dealer) {
            if (isFetched.current === false) {
                setIsFetchingInventorySources(true);

                const newInventorySources: InventorySource[] = [];

                await ApiService.getDspInventorySourceExchanges()
                    .then((response) => {
                        newInventorySources.push(
                            ...response.data.map((model: DspInventorySourceExchange) =>
                                toDspInventorySourceExchangeDTO(model)
                            )
                        );
                    })
                    .catch(() => {
                        // no-op
                    });

                await ApiService.getDspInventorySourceDeals()
                    .then((response) => {
                        newInventorySources.push(
                            ...response.data.map((model: DspInventorySourceDeal) => toDspInventorySourceDealDTO(model))
                        );
                    })
                    .catch(() => {
                        // no-op
                    });

                setInventorySources(newInventorySources);

                setIsFetchingInventorySources(false);
            }

            isFetched.current = true;
        } else {
            setInventorySources([]);
        }
    }, [dealer]);

    const getInventorySourceName = (inventorySource: InventorySource): string => {
        return hydrateInventorySource(inventorySource)?.name;
    };

    const hydrateInventorySource = (unhydratedInventorySource: InventorySource): InventorySource => {
        if (inventorySources.length > 0) {
            const hydratedInventorySource = inventorySources.find(
                (o: InventorySource) => o.id === unhydratedInventorySource.id
            );

            if (hydratedInventorySource) {
                return hydratedInventorySource;
            }
        }

        return unhydratedInventorySource;
    };

    const hydrateInventorySources = (unhydratedInventorySources: InventorySource[]): InventorySource[] => {
        if (inventorySources.length > 0) {
            return unhydratedInventorySources.map((unhydratedInventorySource: InventorySource) =>
                hydrateInventorySource(unhydratedInventorySource)
            );
        }

        return unhydratedInventorySources;
    };

    const getCampaignInventorySources = (campaign: Campaign): InventorySource[] => {
        let hydratedInventorySources: InventorySource[] = [];

        if (campaign.adGroups.length > 0) {
            if (campaign.adGroups[0]?.adGroupInventorySources) {
                hydratedInventorySources = hydrateInventorySources(
                    toInventorySources(campaign.adGroups[0].adGroupInventorySources)
                );
            }
        }

        return hydratedInventorySources;
    };

    // useEffect(() => {
    //     fetchInventorySources();
    // }, []);

    useEffect(() => {
        isFetched.current = false;
    }, [dealer]);

    return {
        inventorySources,
        setInventorySources,
        fetchInventorySources,
        isFetchingInventorySources,
        setIsFetchingInventorySources,
        getInventorySourceName,
        hydrateInventorySource,
        hydrateInventorySources,
        getCampaignInventorySources,
    };
}
