import { Typography } from '@mui/material';
import { Campaign } from '../../types/Campaign';
import Column from '../Column';

export interface CampaignFrequencySummaryProps {
    campaign: Campaign;
}

export default function CampaignFrequencySummary(props: CampaignFrequencySummaryProps) {
    const { campaign } = props;

    return (
        <Column gap={0}>
            Frequency cap
            <Typography variant="caption" color="text.secondary">
                Show no more than {campaign.frequencyMaxImpressions} times per {campaign.frequencyTimeUnitCount}{' '}
                {campaign?.frequencyTimeUnit?.toLowerCase()}
            </Typography>
        </Column>
    );
}
