import { useState } from 'react';

export function useSingleLoader() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const startLoader = (): void => {
        setIsLoading(true);
        setIsLoaded(false);
    };

    const stopLoader = (): void => {
        setIsLoading(false);
        setIsLoaded(true);
    };

    const withLoader = async (callback: Function, milliseconds: number = 0): Promise<void> => {
        startLoader();

        try {
            await callback();
        } finally {
            setTimeout(() => {
                stopLoader();
            }, milliseconds);
        }
    };

    return {
        isLoading,
        setIsLoading,

        isLoaded,
        setIsLoaded,

        startLoader,
        stopLoader,
        withLoader,
    };
}
