import { useCallback, useState } from 'react';
import { ForecastingParameters } from '../types/AdvertiserRequest';
import { useSingleLoader } from './useSingleLoader';
import ApiService from '../ApiService';

export const FORECASTING_STATUS_NARROW = 'narrow';
export const FORECASTING_STATUS_BROAD = 'broad';
export const FORECASTING_STATUS_BALANCED = 'balanced';

export function useForecastingParameters() {
    const $loader = useSingleLoader();
    const {
        isLoading: isFetchingForecastingParameters,
        setIsLoading: setIsFetchingForecastingParameters,

        isLoaded: isFetchedForecastingParameters,
        setIsLoaded: setIsFetchedForecastingParameters,
    } = $loader;

    const [forecastingParameters, setForecastingParameters] = useState<ForecastingParameters>(
        new ForecastingParameters()
    );

    const fetchForecastingParameters = useCallback(() => {
        $loader.withLoader(async () => {
            ApiService.getForecastingParameters()
                .then((response) => {
                    setForecastingParameters(response.data);
                })
                .catch();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const identifyForecastingStatus = (availableReach: number): string => {
        if (availableReach < forecastingParameters.reachNarrow) {
            return FORECASTING_STATUS_NARROW;
        }

        if (availableReach > forecastingParameters.reachBroad) {
            return FORECASTING_STATUS_BROAD;
        }

        return FORECASTING_STATUS_BALANCED;
    };

    return {
        fetchForecastingParameters,
        forecastingParameters,
        setForecastingParameters,

        identifyForecastingStatus,

        isFetchingForecastingParameters,
        setIsFetchingForecastingParameters,

        isFetchedForecastingParameters,
        setIsFetchedForecastingParameters,
    };
}
