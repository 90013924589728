import { styled, tableCellClasses, tableContainerClasses, tableRowClasses } from '@mui/material';
import { Table as BaseTable, TableCell as BaseTableCell, TableContainer as BaseTableContainer } from '@mui/material';

export const Table = styled(BaseTable)(({ theme }) => ({
    borderColor: theme.palette.grey[300],
    [`& .${tableRowClasses.root}`]: {
        backgroundColor: theme.palette.background.default,
    },
    [`& .${tableCellClasses.root}`]: {
        borderColor: theme.palette.grey[300],
        color: theme.palette.text.secondary,
    },
    [`& .${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.background.tableHeader,
        color: theme.palette.text.primary,
        fontWeight: 500,
    },
    // '& .head': {
    //     padding: '5px 0px',
    // },
}));

export const TableContainer = styled(BaseTableContainer)(({ theme }) => ({
    [`&.${tableContainerClasses.root}`]: {
        borderCollapse: 'inherit',
        borderColor: theme.palette.grey[300],
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden',
    },
}));

export const TablePrimaryCell = styled(BaseTableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
        color: theme.palette.text.primary,
    },
}));
