import { useEffect, useState } from 'react';
import { Checkbox, List, ListProps, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import { Location } from '../../types/Location';

export type LocationCheckboxListProps = {
    items: Location[];
    selections: Location[];
    onChange?: (locations: Location[]) => void;
    onAttach?: (location: Location) => void;
    onDetach?: (location: Location) => void;
} & Omit<ListProps, 'onChange'>;

export default function LocationCheckboxList(props: LocationCheckboxListProps) {
    const { items, selections, onAttach, onChange, onDetach, ...listProps } = props;

    const [checkedItems, setCheckedItems] = useState<Location[]>([]);

    const getItemKey = (item: Location): string => {
        return item.id;
    };

    const handleToggle = (value: Location) => () => {
        const currentIndex = checkedItems.indexOf(value);
        const newChecked = [...checkedItems];

        if (currentIndex === -1) {
            if (onAttach) {
                onAttach(value);
            }
            newChecked.push(value);
        } else {
            if (onDetach) {
                onDetach(value);
            }
            newChecked.splice(currentIndex, 1);
        }

        if (onChange) {
            onChange(newChecked);
        }

        setCheckedItems(newChecked);
    };

    useEffect(() => {
        setCheckedItems(items);
    }, [items]);

    return (
        <List {...listProps}>
            {selections.map((location: Location) => {
                const labelId = `checkbox-list-label-${getItemKey(location)}`;

                if (checkedItems.find((o: Location) => getItemKey(o) === getItemKey(location))) {
                    // return null;
                }

                // if (checkedItems.indexOf(location) !== -1) {
                //     return null;
                // }

                return (
                    <ListItem
                        key={getItemKey(location)}
                        // dense
                        disableGutters={false}
                        divider
                        // sx={{ px: 2, py: 1 }}
                        disablePadding
                    >
                        <ListItemButton role={undefined} onClick={handleToggle(location)} sx={{ px: 2 }}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={checkedItems.indexOf(location) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    sx={{ px: 2, py: 0 }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={location.name} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
}
