import { useCallback, useMemo, useState } from 'react';
import { Box, Button, Stack } from '@mui/material';

import CountChip from '../CountChip';
import Row from '../Row';

import { Location } from '../../types/Location';
import LocationSourceDrawer from './LocationSourceDrawer';

export type LocationSourceFieldProps = {
    selections?: Location[];
    fetchSelections?: () => void;
    hydrateSelections?: (selections: Location[]) => Location[];
    onAttach?: (newLocations: Location[]) => void;
    onDetach?: (newLocations: Location[]) => void;
    onChange?: (items: Location[]) => void;
    items?: Location[];
    radius?: number;
    disableRadius?: boolean;
    onRadiusChange?: (newRadius: number) => void;
    mapCenter?: string;

    limit?: number;
    disableLimit?: boolean;
};

export default function LocationSourceField(props: LocationSourceFieldProps) {
    const { items = [], selections = [], fetchSelections, hydrateSelections } = props;
    const { onAttach, onDetach, onChange } = props;
    const { radius = 30, disableRadius = false, onRadiusChange } = props;
    const { mapCenter } = props;
    const { limit = 5, disableLimit = false } = props;

    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [showLimit, setShowLimit] = useState<boolean>(false);

    const limitedItems = useMemo(() => {
        if (!showLimit) {
            return items.slice(0, limit);
        }
        return items;
    }, [items, showLimit, limit]);

    const showMoreLimit = useMemo((): boolean => {
        if (items.length > limit) {
            return !showLimit;
        }
        return false;
    }, [items, showLimit, limit]);

    const showLessLimit = useMemo((): boolean => {
        if (items.length > limit) {
            return showLimit;
        }
        return false;
    }, [items, showLimit, limit]);

    const handleDrawerChange = (newLocations: Location[]): void => {
        if (onChange) {
            onChange(newLocations);
        }
    };

    const handleDrawerClose = useCallback(
        (newLocations: Location[], reason: string) => {
            setDrawerOpen(false);

            if (['save'].includes(reason)) {
                if (onChange) {
                    onChange(newLocations);
                }
            }
        },
        [onChange]
    );

    return (
        <Stack gap={3}>
            <Box>
                <Row useFlexGap flexWrap="wrap" gap={1} sx={{ width: '100%' }}>
                    {limitedItems.map((locationSource: any) => {
                        return (
                            <CountChip
                                key={locationSource.id}
                                variant="outlined"
                                label={locationSource.name}
                                hideCount={true}
                            />
                        );
                    })}
                    {!disableLimit && (
                        <>
                            {showMoreLimit && (
                                <Button variant="text" onClick={() => setShowLimit(true)}>
                                    Show More
                                </Button>
                            )}
                            {showLessLimit && (
                                <Button variant="text" onClick={() => setShowLimit(false)}>
                                    Show Less
                                </Button>
                            )}
                        </>
                    )}
                </Row>
            </Box>

            {onChange && (
                <Box>
                    <Button variant="outlined" onClick={() => setDrawerOpen(true)}>
                        {items.length > 0 ? 'Edit' : 'Select'}
                    </Button>
                </Box>
            )}

            {fetchSelections !== undefined && (
                <LocationSourceDrawer
                    selections={selections}
                    fetchSelections={fetchSelections}
                    hydrateSelections={hydrateSelections}
                    open={drawerOpen}
                    items={items}
                    disableRadius={disableRadius}
                    radius={radius}
                    onRadiusChange={onRadiusChange}
                    mapCenter={mapCenter}
                    onAttach={onAttach}
                    onDetach={onDetach}
                    onChange={handleDrawerChange}
                    onClose={handleDrawerClose}
                />
            )}
        </Stack>
    );
}
