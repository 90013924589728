import { useMemo } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';
import { GridColDef } from '@mui/x-data-grid-pro';

import {
    AudienceSource,
    AudienceSourceFee,
    AudienceSourceCriteria,
    AudienceSourceInventoryForecast,
} from '../../types/AudienceSource';
import { getAudienceSourceInventoryForecast } from '../../hooks/useAudienceSources';
import AudienceSourceCriteriaSearch from './AudienceSourceCriteriaSearch';
import CDButton from '../CDButton';
import CDGrid, { CDGridProProps } from '../CDGridPro';
import Column from '../Column';

export function useAudienceSourceDataGridDefaultColumns(props: { searchCriteria: AudienceSourceCriteria }) {
    const { searchCriteria } = props;

    const defaultColumns: GridColDef[] = useMemo(() => {
        return [
            {
                headerName: 'Audience Name',
                field: 'audienceName',
                filterable: false,
                flex: 1,
                width: 300,
            },
            {
                headerName: 'Daily Reach',
                headerAlign: 'right',
                field: 'dailyReach',
                filterable: false,
                align: 'right',
                width: 120,
                valueGetter: (value, row) => {
                    const audienceSource: AudienceSource = row;
                    const forecast: AudienceSourceInventoryForecast = getAudienceSourceInventoryForecast(
                        audienceSource,
                        'all',
                        'dailyReach'
                    );

                    if (forecast.upperBoundExclusive > 0) {
                        return forecast.upperBoundExclusive;
                    }

                    if (forecast.lowerBoundInclusive > 0) {
                        return forecast.lowerBoundInclusive;
                    }

                    return 0;
                },
                renderCell: (params) => {
                    const audienceSource: AudienceSource = params.row;
                    const forecast: AudienceSourceInventoryForecast = getAudienceSourceInventoryForecast(
                        audienceSource,
                        'all',
                        'dailyReach'
                    );

                    let formatter = Intl.NumberFormat('en', { notation: 'compact' });
                    let values: string[] = [];

                    if (forecast.upperBoundExclusive > 0) {
                        values.push(formatter.format(forecast.upperBoundExclusive));
                    }

                    if (forecast.lowerBoundInclusive > 0) {
                        values.push(formatter.format(forecast.lowerBoundInclusive));
                    }

                    return values.join(' - ');
                },
            },
            {
                headerName: 'Daily Impressions',
                headerAlign: 'right',
                field: 'dailyImpressions',
                filterable: false,
                align: 'right',
                width: 140,
                valueGetter: (value, row) => {
                    const audienceSource: AudienceSource = row;
                    const forecast: AudienceSourceInventoryForecast = getAudienceSourceInventoryForecast(
                        audienceSource,
                        'all',
                        'dailyImpressions'
                    );

                    if (forecast.upperBoundExclusive > 0) {
                        return forecast.upperBoundExclusive;
                    }

                    if (forecast.lowerBoundInclusive > 0) {
                        return forecast.lowerBoundInclusive;
                    }

                    return 0;
                },
                renderCell: (params) => {
                    const audienceSource: AudienceSource = params.row;
                    const forecast: AudienceSourceInventoryForecast = getAudienceSourceInventoryForecast(
                        audienceSource,
                        'all',
                        'dailyImpressions'
                    );

                    let formatter = Intl.NumberFormat('en', { notation: 'compact' });
                    let values: string[] = [];

                    if (forecast.upperBoundExclusive > 0) {
                        values.push(formatter.format(forecast.upperBoundExclusive));
                    }

                    if (forecast.lowerBoundInclusive > 0) {
                        values.push(formatter.format(forecast.lowerBoundInclusive));
                    }

                    return values.join(' - ');
                },
            },
            // {
            //     headerName: 'Fee',
            //     headerAlign: 'right',
            //     field: 'fees',
            //     align: 'right',
            //     width: 140,
            //     valueGetter: (value, row) => {
            //         const audienceSource: AudienceSource = row;
            //         const fee: AudienceSourceFee | undefined = audienceSource.fees.find((fee: AudienceSourceFee) => {
            //             if (searchCriteria.mediaType && searchCriteria.mediaType.toLowerCase()) {
            //                 switch (searchCriteria.mediaType) {
            //                     case 'Display':
            //                         return ['WEB'].includes(fee.impressionSupplyType);

            //                     case 'Video':
            //                         return ['OLV', 'STV'].includes(fee.impressionSupplyType);

            //                     default:
            //                         return (
            //                             searchCriteria.mediaType.toLowerCase() ===
            //                             fee.impressionSupplyType.toLowerCase()
            //                         );
            //                 }
            //             }
            //             return false;
            //         });

            //         if (fee && fee.amount > 0 && fee.scale > 0) {
            //             return fee.amount / fee.scale;
            //         }

            //         return 0;
            //     },
            //     renderCell: (params) => {
            //         const audienceSource: AudienceSource = params.row;
            //         const fee: AudienceSourceFee | undefined = audienceSource.fees.find((fee: AudienceSourceFee) => {
            //             if (searchCriteria.mediaType && searchCriteria.mediaType.toLowerCase()) {
            //                 switch (searchCriteria.mediaType) {
            //                     case 'Display':
            //                         return ['WEB'].includes(fee.impressionSupplyType);

            //                     case 'Video':
            //                         return ['OLV', 'STV'].includes(fee.impressionSupplyType);

            //                     default:
            //                         return (
            //                             searchCriteria.mediaType.toLowerCase() ===
            //                             fee.impressionSupplyType.toLowerCase()
            //                         );
            //                 }
            //             }
            //             return false;
            //         });

            //         if (fee && fee.amount > 0 && fee.scale > 0) {
            //             return `${Utils.formatCurrency(fee.amount / fee.scale, 2)} ${fee.feeCalculationType}`;
            //         }

            //         return `${Utils.formatCurrency(0, 2)} CPM`;
            //     },
            // },
            {
                headerName: 'Cost Type',
                headerAlign: 'right',
                field: 'costType',
                filterable: false,
                align: 'right',
                width: 140,
                valueGetter: (value, row) => {
                    const audienceSource: AudienceSource = row;
                    const fee: AudienceSourceFee | undefined = audienceSource.fees.find((fee: AudienceSourceFee) => {
                        if (searchCriteria.mediaType && searchCriteria.mediaType.toLowerCase()) {
                            switch (searchCriteria.mediaType) {
                                case 'Display':
                                    return ['WEB'].includes(fee.impressionSupplyType);

                                case 'Video':
                                    return ['OLV', 'STV'].includes(fee.impressionSupplyType);

                                default:
                                    return (
                                        searchCriteria.mediaType.toLowerCase() ===
                                        fee.impressionSupplyType.toLowerCase()
                                    );
                            }
                        }
                        return false;
                    });

                    if (fee && fee.amount > 0 && fee.scale > 0) {
                        // return fee.amount / fee.scale;
                        return 'Paid';
                    }

                    return 'Free';
                },
                renderCell: (params) => {
                    return params.value;
                },
            },
        ];
    }, [searchCriteria]);

    return {
        defaultColumns,
    };
}

export function DataGridNoRowsOverlay() {
    return (
        <Stack spacing={1} justifyContent="center" alignItems="center" sx={{ height: '70px' }}>
            <Typography variant="subtitle2">No Data Available</Typography>
        </Stack>
    );
}

export type AudienceSourceDataGridCardProps = {
    rows: AudienceSource[];
    selections: AudienceSource[];
    onSelectionChange?: (selections: AudienceSource[]) => void;
    searchCriteria: AudienceSourceCriteria;
    changeSearchCriteria: (searchCriteria: AudienceSourceCriteria) => void;

    onAttachChange?: (selection: AudienceSource) => void;
    onAttachManyChange?: (selections: AudienceSource[]) => void;

    dataGridProps?: Omit<CDGridProProps<AudienceSource>, 'columns'>;
};

export default function AudienceSourceRowDataGridCard(props: AudienceSourceDataGridCardProps) {
    const { rows, selections } = props;

    const { defaultColumns } = useAudienceSourceDataGridDefaultColumns({
        searchCriteria: props.searchCriteria,
    });

    const columns: GridColDef[] = [
        {
            headerName: '',
            field: 'Actions',
            width: 60,
            hideSortIcons: true,
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <IconButton
                        onClick={() => {
                            if (props.onAttachChange) {
                                props.onAttachChange(params.row);
                            }
                        }}
                    >
                        <AddCircleOutlineIcon fontSize="small" />
                    </IconButton>
                );
            },
        },
        ...defaultColumns,
    ];
    return (
        <Column gap={0}>
            <Stack
                sx={{
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                    borderBottom: 'none',
                    borderTopRightRadius: (theme) => `${theme.shape.borderRadius}px`,
                    borderTopLeftRadius: (theme) => `${theme.shape.borderRadius}px`,
                }}
            >
                <AudienceSourceCriteriaSearch
                    searchCriteria={props.searchCriteria}
                    changeSearchCriteria={props.changeSearchCriteria}
                />
                <Box sx={{ paddingX: 2, paddingBottom: 2 }}>
                    <Typography variant="subtitle1">Advertiser Audiences</Typography>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            visibility: rows.length > 0 ? 'visible' : 'hidden',
                        }}
                    >
                        <Typography component="span" color="text.primary" variant="subtitle2">
                            {selections.length}
                        </Typography>
                        <Typography component="span" color="text.secondary" variant="subtitle2">
                            /{rows.length} selected
                        </Typography>
                    </Typography>
                </Box>
                <Box sx={{ px: 2, pb: 1 }}>
                    <CDButton
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() => {
                            if (props.onAttachManyChange) {
                                props.onAttachManyChange(selections);
                            }
                        }}
                        sx={{
                            visibility: selections.length ? 'visible' : 'hidden',
                        }}
                    >
                        Bulk Add
                    </CDButton>
                </Box>
            </Stack>
            <Box
                sx={{
                    height: rows.length > 0 ? 'auto' : '150px',
                    width: '100%',
                    flexGrow: 1,
                }}
            >
                <CDGrid
                    pageSize={10}
                    slots={{
                        noRowsOverlay: DataGridNoRowsOverlay,
                        toolbar: undefined,
                    }}
                    PaperProps={{
                        elevation: 0,
                    }}
                    {...props.dataGridProps}
                    checkboxSelection={true}
                    columns={columns}
                    rows={rows}
                    sx={{
                        '& .MuiDataGrid-main': {
                            mt: -0.5,
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            color: 'text.primary',
                        },
                        '& .MuiDataGrid-toolbarContainer': {
                            borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
                            borderRight: (theme) => `1px solid ${theme.palette.divider}`,
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
                            borderRight: (theme) => `1px solid ${theme.palette.divider}`,
                        },
                        '& [data-field="dailyReach"]': {
                            color: 'text.secondary',
                        },
                        '& [data-field="dailyImpressions"]': {
                            color: 'text.secondary',
                        },
                        '& [data-field="costType"]': {
                            color: 'text.secondary',
                        },
                    }}
                />
            </Box>
        </Column>
    );
}
