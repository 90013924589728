import {
    AudiotrackOutlined,
    PhotoOutlined,
    VideocamOutlined,
    LiveTv as LiveTvIcon,
    OndemandVideo as OndemandVideoIcon,
} from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import theme from '../theme';

export default function CampaignType(props: {
    mediaType: string | undefined;
    campaignType: string | undefined;
    strategyType: string | undefined;
    iconOnly?: boolean;
    small?: boolean;
}) {
    let title = props.mediaType + ' ' + props.campaignType + ' ' + props.strategyType;
    let icon;
    switch (props.mediaType) {
        case 'Audio':
            icon = <AudiotrackOutlined />;
            break;

        case 'Display':
            icon = <PhotoOutlined />;
            break;

        case 'Video':
            icon = <VideocamOutlined />;
            break;

        case 'Online Video':
            icon = <OndemandVideoIcon />;
            break;

        case 'Streaming TV':
            icon = <LiveTvIcon />;
            break;

        default:
            icon = <span>{props.mediaType}</span>;
    }

    if (props.small) {
        return (
            <Tooltip placement="top" title={title}>
                <span
                    style={{
                        width: '24px',
                        height: '24px',
                        color: theme.palette.primary.main,
                        alignItems: 'center',
                        alignContent: 'center',
                        paddingTop: '1px',
                    }}
                >
                    {icon}
                </span>
            </Tooltip>
        );
    }

    return (
        <Tooltip placement="top" title={title}>
            <Typography fontSize="1em">
                <span
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',

                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    <span
                        style={{
                            width: '36px',
                            height: '36px',
                            color: theme.palette.primary.main,
                            backgroundColor: '#F2F5FF',
                            borderRadius: '50%',
                            alignItems: 'center',
                            alignContent: 'center',
                            paddingLeft: '6px',
                            paddingTop: '6px',
                        }}
                    >
                        {icon}
                    </span>
                    {!props.iconOnly && (
                        <>
                            <span>{props.campaignType}</span>
                            <span>{props.strategyType}</span>
                        </>
                    )}
                </span>
            </Typography>
        </Tooltip>
    );
}
