import { useMemo } from 'react';
import { Typography } from '@mui/material';
import { Campaign } from '../../types/Campaign';
import Column from '../Column';

export interface CampaignCreativeTypeSummaryProps {
    campaign: Campaign;
}

export default function CampaignCreativeTypeSummary(props: CampaignCreativeTypeSummaryProps) {
    const { campaign } = props;

    const label = useMemo((): string => {
        switch (campaign.campaignType) {
            case 'AdTag':
                return 'Ad Tag Creative';

            case 'DCO':
                return 'Dynamic Creative';

            case 'Static':
                return 'Static Creative';
        }

        return campaign.campaignType as string;
    }, [campaign.campaignType]);

    return (
        <Column gap={0}>
            <Typography variant="body1">{label}</Typography>
        </Column>
    );
}
