import { styled } from '@mui/material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    FormGroup,
    FormControlLabel as BaseFormControlLabel,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

import { AudienceSourceCriteria } from '../../types/AudienceSource';
import { AudienceSourceCategory } from '../../types/AudienceSourceCategory';

const FormControlLabel = styled(BaseFormControlLabel)(({ theme }) => ({
    '& .MuiFormControlLabel-label': {
        color: theme.palette.text.primary,
        fontSize: theme.typography.body2.fontSize,
    },
    '& .MuiCheckbox-root': {
        marginRight: theme.spacing(2),
    },
}));

export type AudienceSourceFilterProps = {
    audienceSourceCategories: AudienceSourceCategory[];
    searchCriteria: AudienceSourceCriteria;
    changeSearchCriteria: (searchCriteria: AudienceSourceCriteria) => void;
};

export default function AudienceSourceFilter(props: AudienceSourceFilterProps) {
    const { audienceSourceCategories } = props;
    const { searchCriteria, changeSearchCriteria } = props;

    const handleChange = (event: any): void => {
        if (event?.target?.value) {
            const categoryPair: string = event.target.value;
            const [categoryCode, subCategoryCode] = categoryPair.split('|');

            const audienceSourceCategory = audienceSourceCategories.find(
                (_audienceSourceCategory: AudienceSourceCategory) => {
                    return _audienceSourceCategory.code === categoryCode;
                }
            );

            if (event.target.checked) {
                // Always include the parent category
                if (!searchCriteria.categories.includes(categoryCode)) {
                    searchCriteria.categories.push(categoryCode);
                }

                if (subCategoryCode) {
                    searchCriteria.categories.push(categoryPair);
                } else {
                    if (audienceSourceCategory?.children?.length) {
                        audienceSourceCategory.children.forEach((childCategory: AudienceSourceCategory) => {
                            if (!searchCriteria.categories.includes(childCategory.code)) {
                                searchCriteria.categories.push(childCategory.code);
                            }
                        });
                    }
                }

                changeSearchCriteria(searchCriteria);
            } else {
                changeSearchCriteria({
                    ...searchCriteria,
                    categories: searchCriteria.categories.filter((_categoryPair: string) => {
                        const excludedCategories = subCategoryCode
                            ? [categoryPair]
                            : [
                                  categoryCode,
                                  ...(audienceSourceCategory?.children?.map(
                                      (child: AudienceSourceCategory) => child.code
                                  ) || []),
                              ];

                        return !excludedCategories.includes(_categoryPair);
                    }),
                });
            }
        }
    };

    return (
        <FormGroup onChange={handleChange}>
            {audienceSourceCategories.map((category: AudienceSourceCategory, index: number) => {
                return (
                    <Box
                        key={category.id}
                        sx={{
                            borderBottomWidth: 1,
                            borderBottomColor: 'divider',
                            borderBottomStyle: 'solid',
                        }}
                    >
                        <Accordion disableGutters square elevation={0}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                sx={{
                                    '& .MuiAccordionSummary-content': {
                                        my: 0,
                                    },
                                }}
                            >
                                <FormControlLabel
                                    control={<Checkbox checked={searchCriteria.categories.includes(category.code)} />}
                                    label={category.name}
                                    value={category.code}
                                />
                            </AccordionSummary>
                            <AccordionDetails
                                sx={{
                                    height: 250,
                                    overflowY: 'auto',
                                }}
                            >
                                {category.children.map((subCategory: AudienceSourceCategory) => {
                                    return (
                                        <Box key={subCategory.code} sx={{ ml: 5 }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={searchCriteria.categories.includes(subCategory.code)}
                                                    />
                                                }
                                                label={subCategory.name}
                                                value={subCategory.code}
                                                sx={{ mr: 0 }}
                                            />
                                        </Box>
                                    );
                                })}
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                );
            })}
        </FormGroup>
    );
}
