import { ChangeEvent, MouseEvent, useCallback, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControl,
    Grid,
    GridProps,
    InputAdornment,
    Typography,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

import { Location } from '../../types/Location';
import { CDTextField } from '../CDTextField';
import Column from '../Column';
import Row from '../Row';
import LocationCheckboxList from './LocationCheckboxList';
import LocationList from './LocationList';

export const toCampaignZipcodes = (locations: Location[]): string => {
    return locations.map((location: Location) => location.id).join(',');
};

type LocationSourceListProps = {
    items: Location[];
    selections: Location[];
    onChange?: (items: Location[]) => void;
    onAttach?: (items: Location[]) => void;
    onDetach?: (items: Location[]) => void;
    onSearch?: (searchText: string) => void;
    loading?: boolean;

    gridProps?: GridProps;
};

export default function LocationSourceList(props: LocationSourceListProps) {
    const { loading } = props;
    const { items, selections } = props;
    const { onAttach, onChange, onDetach, onSearch } = props;
    const { gridProps } = props;

    const [searchText, setSearchText] = useState<string>('');
    const [filteredSelections, setFilteredSelections] = useState<Location[]>([]);

    const searchSelections = useCallback(
        (rawSearchText: string = '') => {
            if (rawSearchText.length > 0) {
                setFilteredSelections([
                    ...selections
                        .filter((item: Location) => {
                            const regex = new RegExp(rawSearchText, 'i');
                            return regex.test(item.name);
                        })
                        .slice(0, 50),
                ]);
            } else {
                if (selections.length > 50) {
                    setFilteredSelections([...selections.slice(0, 50)]);
                }
            }
        },
        [selections]
    );

    const handleChange = useCallback(
        (items: Location[]) => {
            if (onChange) {
                onChange(items);
            }
        },
        [onChange]
    );

    const handleAttach = useCallback(
        (items: Location[]) => {
            if (onAttach) {
                onAttach(items);
            }
        },
        [onAttach]
    );

    const handleDetach = useCallback(
        (items: Location[]) => {
            if (onDetach) {
                onDetach(items);
            }
        },
        [onDetach]
    );

    const handleSearchSelections = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
        searchSelections(event.target.value);

        if (onSearch) {
            onSearch(searchText);
        }
    };

    // useEffect(() => {
    //     // Initially trigger the search selections
    //     searchSelections();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const handleSelectAll = (event: MouseEvent<HTMLButtonElement>): void => {
        handleChange(filteredSelections);
    };

    const handleClearAll = (event: MouseEvent<HTMLButtonElement>): void => {
        handleChange([]);
    };

    return (
        <Grid container spacing={0} sx={{ alignItems: 'stretch' }} {...gridProps}>
            <Grid item xs={6} sx={{ height: '100%' }}>
                <Column sx={{ height: '100%' }}>
                    <Box sx={{ px: 2, pt: 3 }}>
                        <FormControl fullWidth size="small">
                            <CDTextField
                                label="Selects Location(s) to Target"
                                value={searchText}
                                onChange={handleSearchSelections}
                                shrinkLabel
                                // multiline
                                // rows={3}
                                placeholder="Enter city, state or zip code"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" color="action" />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Box>

                    <Box sx={{ overflow: 'auto' }}>
                        <Divider />

                        {loading === true && (
                            <Column alignItems="center" justifyContent="center" sx={{ height: 200 }}>
                                <CircularProgress />
                            </Column>
                        )}
                        {loading === false && (
                            <>
                                {filteredSelections.length > 0 && (
                                    <Column gap={0}>
                                        <Box
                                            sx={{
                                                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                                                px: 2,
                                                py: 1,
                                            }}
                                        >
                                            <Row alignItems="center" justifyContent="space-between">
                                                {filteredSelections.length > 0 && (
                                                    <Button variant="text" size="small" onClick={handleSelectAll}>
                                                        Select All
                                                    </Button>
                                                )}

                                                {items.length > 0 && (
                                                    <Button variant="text" size="small" onClick={handleClearAll}>
                                                        Clear All
                                                    </Button>
                                                )}
                                            </Row>
                                        </Box>

                                        <LocationCheckboxList
                                            items={items}
                                            selections={filteredSelections}
                                            onAttach={(location: Location) => {
                                                handleAttach([location]);
                                            }}
                                            onChange={(newLocations: Location[]): void => {
                                                handleChange(newLocations);
                                            }}
                                            sx={{
                                                bgcolor: 'background.paper',
                                                overflow: 'auto',
                                                position: 'relative',
                                            }}
                                        />
                                    </Column>
                                )}
                                {filteredSelections.length === 0 && (
                                    <Column alignItems="center" justifyContent="center" sx={{ height: 200 }}>
                                        <Typography variant="body2" color="text.secondary">
                                            No results
                                        </Typography>
                                    </Column>
                                )}
                            </>
                        )}
                    </Box>
                </Column>
            </Grid>

            <Grid
                item
                xs={6}
                sx={{
                    borderLeft: 1,
                    borderColor: 'divider',
                    height: '100%',
                }}
            >
                <Column gap={0} sx={{ height: '100%' }}>
                    <Row
                        alignItems="center"
                        sx={{
                            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                            px: 2,
                            py: 1,
                            height: 48,
                        }}
                    >
                        <Row alignItems="center" justifyContent="space-between">
                            <Row gap={0.5}>
                                <Typography variant="subtitle2" color="text.primary">
                                    {items.length}
                                </Typography>{' '}
                                <Typography variant="subtitle2" color="text.secondary">
                                    Selected
                                </Typography>
                            </Row>

                            {items.length > 0 && (
                                <Button variant="text" size="small" onClick={handleClearAll}>
                                    Clear All
                                </Button>
                            )}
                        </Row>
                    </Row>

                    <Box sx={{ overflow: 'auto' }}>
                        <LocationList
                            items={items}
                            onDetach={(location: Location) => {
                                const index = items.indexOf(location);

                                if (index > -1) {
                                    items.splice(index, 1);
                                    handleDetach([location]);
                                    handleChange([...items]);
                                }
                            }}
                            sx={{
                                bgcolor: 'background.paper',
                                // height: '100%',
                                // overflow: 'auto',
                                // position: 'relative',
                                pt: 0,
                            }}
                        />
                    </Box>
                </Column>
            </Grid>
        </Grid>
    );
}
