import { useMemo, useState } from 'react';

export class Stepper {
    activeStep: number = 0;
    canStep: Function = (): boolean => true;
    canNextStep: Function = (): boolean => true;
    canPreviousStep: Function = (): boolean => true;
    defaultActiveStep: number = 0;
    errorStep: number = -1;
    hasError: boolean = false;
    hasErrorStep: Function = (step: number): boolean => true;
    jumpable: boolean = false;
    hasNextStep: Function = () => false;
    hasPreviousStep: Function = () => false;
    isActiveStep: Function = (step: number) => false;
    maxStep: number = 0;
    minStep: number = 0;
    resetStep: Function = () => {};
    setActiveStep: Function = (step: number): void => {};
    setErrorStep: Function = (step: number): void => {};
    toDefaultStep: Function = () => {};
    toNextStep: Function = () => {};
    toPreviousStep: Function = () => {};
    toStep: Function = (step: number) => {};
}

export type useStepperProps = {
    defaultActiveStep?: number;
    canStep?: (step: number) => boolean;
    canNextStep?: (step: number) => boolean;
    canPreviousStep?: (step: number) => boolean;
    jumpable?: boolean;
    maxStep: number;
    minStep: number;
    onBeforeStep?: (step: number) => void;
    onBeforeNextStep?: (step: number) => void;
    onBeforePreviousStep?: (step: number) => void;
    onStep?: (step: number) => void;
    onNextStep?: (step: number) => void;
    onPreviousStep?: (step: number) => void;
};

export default function useStepper(props: useStepperProps): Stepper {
    const {
        jumpable = false,
        maxStep,
        minStep,
        onBeforeStep,
        onStep,
        onBeforeNextStep,
        onBeforePreviousStep,
        onNextStep,
        onPreviousStep,
    } = props;
    const defaultActiveStep = useMemo((): number => {
        return props.defaultActiveStep ?? 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [activeStep, setActiveStep] = useState<number>(defaultActiveStep);
    const [errorStep, setErrorStep] = useState<number>(-1);

    const hasError = useMemo((): boolean => {
        return errorStep > -1;
    }, [errorStep]);

    const hasErrorStep = (step: number): boolean => {
        return step === errorStep;
    };

    const canStep = (step: number): boolean => {
        if (props.canStep) {
            return props.canStep(step);
        }

        if (maxStep < step || minStep > step) {
            return false;
        }

        return true;
    };

    const canNextStep = (): boolean => {
        if (props.canNextStep) {
            return props.canNextStep(activeStep);
        }

        return true;
    };

    const canPreviousStep = (): boolean => {
        if (props.canPreviousStep) {
            return props.canPreviousStep(activeStep);
        }

        return true;
    };

    const toDefaultStep = (): void => {
        setActiveStep(defaultActiveStep);
    };

    const toStep = (step: number): void => {
        if (onBeforeStep) {
            onBeforeStep(step);
        }

        if (canStep(step)) {
            setActiveStep(step);

            if (onStep) {
                onStep(step);
            }
        }
    };

    const toPreviousStep = (): void => {
        if (onBeforePreviousStep) {
            onBeforePreviousStep(activeStep);
        }

        if (canPreviousStep()) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);

            if (onPreviousStep) {
                onPreviousStep(activeStep);
            }
        }
    };

    const toNextStep = (): void => {
        if (onBeforeNextStep) {
            onBeforeNextStep(activeStep);
        }

        if (canNextStep()) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);

            if (onNextStep) {
                onNextStep(activeStep);
            }
        }
    };

    const isActiveStep = (step: number): boolean => {
        return activeStep === step;
    };

    const hasNextStep = (): boolean => {
        return activeStep < maxStep;
    };

    const hasPreviousStep = (): boolean => {
        return activeStep > minStep;
    };

    const resetStep = (): void => {
        setActiveStep(0);
    };

    return {
        activeStep,
        canStep,
        canNextStep,
        canPreviousStep,
        defaultActiveStep,
        errorStep,
        hasError,
        hasErrorStep,
        jumpable,
        toDefaultStep,
        toNextStep,
        toPreviousStep,
        toStep,
        hasNextStep,
        hasPreviousStep,
        isActiveStep,
        maxStep,
        minStep,
        resetStep,
        setActiveStep,
        setErrorStep,
    };
}
