import { deepmerge } from '@mui/utils';
import {
    DataGrid,
    DataGridProps,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    gridClasses,
    GridColumns,
    gridPageCountSelector,
    gridPageSelector,
    GridRenderCellParams,
    GridSelectionModel,
    GridValidRowModel,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { Pagination, Paper } from '@mui/material';
import { CheckOutlined } from '@mui/icons-material';
import theme from '../theme';

export type CDGridProps<T extends GridValidRowModel> = DataGridProps<T> & {
    id?: string;
    disableSelectionOnclick?: boolean;
    selectionEnabled?: boolean;
    selectionsChanged?: (gridSelectionModel: GridSelectionModel) => void;
    rowsPerPage?: number[];
    disabled?: boolean;
    PaperProps?: Object;
    variant?: 'contained' | 'outlined' | 'elevation';
} & React.RefAttributes<HTMLDivElement>;

export function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Pagination
            showFirstButton
            showLastButton
            color="primary"
            count={pageCount}
            page={page + 1}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
    );
}

export default function CDGrid<T extends GridValidRowModel>(props: CDGridProps<T>) {
    const { variant } = props;

    const [columns, setColumns] = useState<GridColumns>([]);
    const [pageSize, setPageSize] = useState(props.pageSize ? props.pageSize : 10);
    const [disableSelectionOnclick] = useState(props.disableSelectionOnclick ? props.disableSelectionOnclick : false);

    useEffect(() => {
        for (let i = 0; i < props.columns.length; i++) {
            props.columns[i].headerClassName = 'grid-header';
        }
        if (props.selectionEnabled) {
            if (props.disabled) {
                let columns = [
                    {
                        width: 100,
                        ...GRID_CHECKBOX_SELECTION_COL_DEF,
                        headerClassName: 'grid-header',
                        renderCell: (params: GridRenderCellParams) => {
                            let x: number[] = props.selectionModel ? (props.selectionModel as number[]) : [];
                            if (x.includes(params.id as number)) {
                                return <CheckOutlined />;
                            } else {
                                return <></>;
                            }
                        },
                    },
                    ...props.columns,
                ];
                setColumns(columns);
            } else {
                let columns = [
                    {
                        width: 100,
                        ...GRID_CHECKBOX_SELECTION_COL_DEF,
                        headerClassName: 'grid-header',
                    },
                    ...props.columns,
                ];
                setColumns(columns);
            }
        } else {
            setColumns(props.columns);
        }
    }, [props.columns, props.disabled, props.selectionEnabled, props.selectionModel]);

    const components = props.components ? { ...props.components } : {};
    components.Pagination = CustomPagination;

    let PaperPropsProp = props.PaperProps ?? {};
    let sxProp = props.sx ?? {};
    sxProp = deepmerge(sxProp, {
        border: 'none',
        [`& .${gridClasses.toolbarContainer} .MuiToolbar-root`]: {
            minHeight: 'inherit',
        },
        [`& .${gridClasses.row}`]: {
            cursor: props.onRowClick ? 'pointer' : 'default',
        },
        [`& .odd`]: {
            backgroundColor: theme.palette.grey[200],
        },
        [`& .${gridClasses.columnHeader}:not(.${gridClasses.columnHeaderCheckbox})`]: {
            paddingX: 2,
        },
        [`& .${gridClasses.columnHeaderTitle}`]: {
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightMedium,
        },
        [`& .${gridClasses.columnHeaderTitleContainerContent} > strong`]: {
            fontWeight: theme.typography.fontWeightMedium,
        },
        [`& .${gridClasses.columnHeaders}`]: {
            color: theme.palette.text.secondary,
        },
        [`& .${gridClasses.cell}`]: {
            color: theme.palette.text.secondary,
            paddingX: 2,
        },
        [`& .${gridClasses.cell}:focus-within`]: {
            outline: 'none',
        },
        [`& .${gridClasses.cell}:focus`]: {
            outline: 'none',
        },
        [`& .${gridClasses.columnHeader}:focus-within`]: {
            outline: 'none',
        },
        [`& .${gridClasses.columnHeader}:focus`]: {
            outline: 'none',
        },
        [`& .${gridClasses.columnSeparator}`]: {
            visibility: 'hidden',
        },
        ...sxProp,
    });

    if (variant === 'contained') {
        sxProp = deepmerge(sxProp, {
            [`& .${gridClasses.virtualScrollerContent}`]: {
                borderLeftWidth: 0,
                borderRightWidth: 0,
            },
        });
    }

    if (!columns || columns.length <= 0) {
        return null;
    }

    return (
        <Paper
            id={props.id}
            variant={variant === 'elevation' ? 'elevation' : 'outlined'}
            elevation={0}
            sx={{
                height: '100%',
            }}
            {...PaperPropsProp}
        >
            <DataGrid
                variant="contained"
                autoHeight
                checkboxSelection={props.selectionEnabled}
                onSelectionModelChange={(newSelectionModel) => {
                    if (props.selectionsChanged) {
                        props.selectionsChanged(newSelectionModel);
                    }
                }}
                hideFooterSelectedRowCount
                disableSelectionOnClick={disableSelectionOnclick}
                density={props.density ? props.density : 'standard'}
                pageSize={props.autoPageSize === true ? undefined : pageSize}
                rowsPerPageOptions={props.rowsPerPage ? props.rowsPerPage : [10]}
                onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
                paginationMode={props.paginationMode ? props.paginationMode : 'client'}
                {...props}
                components={components}
                columns={columns}
                sx={sxProp}
            />
        </Paper>
    );
}
