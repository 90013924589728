import { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material';
import {
    Box,
    Checkbox,
    FormGroup,
    FormControlLabel as BaseFormControlLabel,
    Drawer,
    DrawerProps,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import { Card, CardContent, CardHeader } from '../../pages/campaign/partials/CampaignWizard/CampaignWizardStyles';
import { ClearOutlined as ClearOutlinedIcon } from '@mui/icons-material';
import { GridFilterModel, GridLogicOperator } from '@mui/x-data-grid-pro';

import { InventorySource, InventorySourceType, InventorySourceCriteria } from '../../types/Inventory';
import { StickyBottomAppBar } from '../../components/AppBar';
import { filterGridRows } from '../../hooks/useDataGridFilter';
import { useInventorySourceTypes } from '../../hooks/useInventorySourceTypes';
import CDButton, { CDDefaultButton } from '../CDButton';
import Column from '../Column';
import InventorySourceDataGridCard from './InventorySourceDataGridCard';
import Row from '../Row';

const FormControlLabel = styled(BaseFormControlLabel)(({ theme }) => ({
    '& .MuiFormControlLabel-label': {
        color: theme.palette.text.primary,
        fontSize: theme.typography.body2.fontSize,
    },
    '& .MuiCheckbox-root': {
        marginRight: theme.spacing(2),
    },
}));

export type InventorySourceDrawerProps = {
    searchCriteria: InventorySourceCriteria;
    changeSearchCriteria: (searchCriteria: InventorySourceCriteria) => void;

    // Use this hook to provide actual data
    // '../../hooks/useInventorySources'
    selections: InventorySource[];
    fetchSelections?: () => void;
    hydrateSelections?: (selections: InventorySource[]) => InventorySource[];

    items?: InventorySource[];
    onChange?: (items: InventorySource[]) => void;
    onClose(items: InventorySource[], reason: 'cancel' | 'save'): void;
} & Omit<DrawerProps, 'onChange' | 'onClose'>;

export default function InventorySourceDrawer(props: InventorySourceDrawerProps) {
    const {
        searchCriteria,
        changeSearchCriteria,
        onChange,
        onClose,
        items,
        selections,
        fetchSelections,
        hydrateSelections,
        ...drawerProps
    } = props;

    const $inventorySourceTypes = useInventorySourceTypes();

    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [selectedItems, setSelectedItems] = useState<InventorySource[]>([]);

    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        logicOperator: GridLogicOperator.Or,
        items: [],
    });

    const closeDrawer = useCallback(
        (newItems: InventorySource[], reason: 'cancel' | 'save') => {
            if (reason === 'cancel') {
                // Restore the selected items.
                setSelectedItems(items ?? []);
            }

            onClose(newItems, reason);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [onClose, items]
    );

    useEffect(() => {
        if (items) {
            items.forEach((item: InventorySource) => {
                if (!searchCriteria.inventorySourceTypes.includes(item.inventorySourceType)) {
                    searchCriteria.inventorySourceTypes.push(item.inventorySourceType);
                }
            });

            changeSearchCriteria({
                ...searchCriteria,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    useEffect(() => {
        switch (searchCriteria.mediaType) {
            case 'Display':
                filterModel.items = [
                    ...filterModel.items.filter((item: any) => {
                        return !['inventoryType', 'name'].includes(item.field);
                    }),
                    {
                        id: 1,
                        field: 'inventoryType',
                        operator: 'isAnyOf',
                        value: ['DISPLAY'],
                    },
                    {
                        id: 2,
                        field: 'name',
                        operator: 'isAnyOfContains',
                        value: ['Display'],
                    },
                ];
                break;

            case 'Video':
                filterModel.items = [
                    ...filterModel.items.filter((item: any) => {
                        return !['inventoryType', 'name'].includes(item.field);
                    }),
                    {
                        id: 1,
                        field: 'inventoryType',
                        operator: 'isAnyOf',
                        value: ['VIDEO'],
                    },
                    {
                        id: 2,
                        field: 'name',
                        operator: 'isAnyOfContains',
                        value: ['OLV', 'TV', 'Video'],
                    },
                ];
                break;

            case 'Online Video':
                filterModel.items = [
                    ...filterModel.items.filter((item: any) => {
                        return !['inventoryType', 'name'].includes(item.field);
                    }),
                    {
                        id: 1,
                        field: 'inventoryType',
                        operator: 'isAnyOf',
                        value: ['VIDEO'],
                    },
                    {
                        id: 2,
                        field: 'name',
                        operator: 'isAnyOfContains',
                        value: ['OLV', 'Video'],
                    },
                ];
                break;

            case 'Streaming TV':
                filterModel.items = [
                    ...filterModel.items.filter((item: any) => {
                        return !['inventoryType', 'name'].includes(item.field);
                    }),
                    {
                        id: 1,
                        field: 'name',
                        operator: 'isAnyOfContains',
                        value: ['TV'],
                    },
                ];
                break;
        }

        if (searchCriteria?.runPrimeVideoOnly) {
            filterModel.logicOperator = GridLogicOperator.And;
            filterModel.items = [
                ...filterModel.items.filter((item: any) => {
                    if (item.field === 'inventoryType') {
                        return false;
                    }
                    if (item.field === 'name') {
                        return false;
                        // return item.operator !== 'contains';
                    }
                    return true;
                }),
                {
                    id: 3,
                    field: 'name',
                    operator: 'contains',
                    value: 'Prime Video',
                },
            ];
        } else {
            filterModel.logicOperator = GridLogicOperator.Or;
            filterModel.items = [
                ...filterModel.items.filter((item: any) => {
                    if (item.field === 'name') {
                        return item.operator !== 'contains';
                    }
                    return true;
                }),
            ];
        }

        setFilterModel({ ...filterModel });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchCriteria]);

    useEffect(() => {
        if (fetchSelections) {
            fetchSelections();
        }
    }, [fetchSelections]);

    useEffect(() => {
        if (items && items[0]?.inventoryType) {
            const filteredSelectedItems: InventorySource[] = filter(items);

            if (items.length !== filteredSelectedItems.length) {
                if (onChange) {
                    onChange(filteredSelectedItems);
                }
            }
        }

        setSelectedItems(items ?? []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    useEffect(() => {
        // Make sure we have the list of inventory sources
        // so we will have the inventory source names
        // to display for selected inventory sources.

        if (hydrateSelections) {
            if (selections.length > 0 && items?.length) {
                const hydratedSelectedItems = hydrateSelections(items);

                if (onChange) {
                    onChange(hydratedSelectedItems);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selections]);

    const handleChangeSelections = (items: InventorySource[]): void => {
        setIsDirty(true);
        setSelectedItems(items);
    };

    const filter = (items: InventorySource[]): InventorySource[] => {
        return filterGridRows(
            items
                .filter((ie: InventorySource) => {
                    if (ie?.deal) {
                        return ie.deal.dealStatus === 'ACTIVE';
                    }
                    return true;
                })
                // .filter((ie: InventorySource) => {
                //     if (ie.inventoryType) {
                //         if (searchCriteria?.mediaType?.length) {
                //             switch (searchCriteria.mediaType) {
                //                 case 'Display':
                //                     return ['DISPLAY', 'UNKNOWN'].includes(ie.inventoryType as any);
                //                 case 'Video':
                //                     return ['VIDEO', 'UNKNOWN'].includes(ie.inventoryType as any);
                //                 case 'Audio':
                //                     return true;
                //                 default:
                //                     return false;
                //             }
                //         }
                //     }

                //     return true;
                // })
                .filter((ie: InventorySource) => {
                    if (ie.inventorySourceType) {
                        if (searchCriteria?.inventorySourceTypes?.length) {
                            return searchCriteria.inventorySourceTypes.includes(ie.inventorySourceType as any);
                        }
                    }

                    return true;
                })
                .filter((ie: InventorySource) => {
                    if (searchCriteria.searchText) {
                        if (ie.name) {
                            return ie.name.toLowerCase().indexOf(searchCriteria.searchText.toLowerCase()) > -1;
                        }
                    }
                    return true;
                }),
            filterModel
        );
    };

    const filteredSelections = useMemo((): InventorySource[] => {
        return filter(selections);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchCriteria, selections]);

    const handleChangeInventorySourceType = useCallback(
        (event: any): void => {
            if (event?.target?.value) {
                const inventorySourceType: string = event.target.value;

                if (event.target.checked) {
                    if (!searchCriteria.inventorySourceTypes.includes(inventorySourceType)) {
                        searchCriteria.inventorySourceTypes.push(inventorySourceType);
                    }

                    //
                    setSelectedItems((prevSelectedItems: InventorySource[]): InventorySource[] => {
                        return [
                            ...prevSelectedItems.filter((item: InventorySource) => {
                                return item.inventorySourceType !== inventorySourceType;
                            }),
                            ...filter(selections).filter((item: InventorySource) => {
                                return item.inventorySourceType === inventorySourceType;
                            }),
                        ];
                    });

                    //
                    changeSearchCriteria(searchCriteria);
                } else {
                    //
                    setSelectedItems((prevSelectedItems: InventorySource[]): InventorySource[] => {
                        return [
                            ...prevSelectedItems.filter((item: InventorySource) => {
                                return item.inventorySourceType !== inventorySourceType;
                            }),
                        ];
                    });

                    const index = searchCriteria.inventorySourceTypes.indexOf(inventorySourceType);

                    if (index > -1) {
                        searchCriteria.inventorySourceTypes.splice(index, 1);
                    }

                    //
                    changeSearchCriteria(searchCriteria);
                }

                //
                setIsDirty(true);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [changeSearchCriteria, searchCriteria, selections]
    );

    return (
        <Drawer
            anchor="right"
            onClose={() => closeDrawer([], 'cancel')}
            {...drawerProps}
            sx={{
                width: 4 / 5,
                flexShrink: 0,
                zIndex: (theme) => theme.zIndex.drawer + 1,

                '& .MuiDrawer-paper': {
                    width: 4 / 5,
                    boxSizing: 'border-box',
                },
            }}
        >
            <Column
                justifyContent="space-between"
                sx={{
                    minHeight: '480px',
                    height: '100%',
                }}
            >
                <Box>
                    <Box
                        component="header"
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            paddingX: 3,
                            paddingY: 2,
                        }}
                    >
                        <Row justifyContent="space-between" alignItems="center" spacing={2}>
                            <Stack spacing={1}>
                                <Typography variant="h6">Select Advertising Inventory Supply</Typography>
                            </Stack>

                            <IconButton onClick={() => closeDrawer([], 'cancel')} edge="end">
                                <ClearOutlinedIcon fontSize="medium" color="action" />
                            </IconButton>
                        </Row>
                    </Box>

                    <Box sx={{ padding: 3 }}>
                        <Row gap={3}>
                            <Card variant="outlined" sx={{ width: 4 / 12, minHeight: '65vh' }}>
                                <CardHeader
                                    title={
                                        <Row spacing={0.5} alignItems="center">
                                            <Typography variant="subtitle2" color="text.primary">
                                                Select Advertising Inventory Source
                                            </Typography>
                                        </Row>
                                    }
                                />
                                <CardContent>
                                    <FormGroup onChange={handleChangeInventorySourceType}>
                                        {$inventorySourceTypes.inventorySourceTypes.length > 0 &&
                                            $inventorySourceTypes.inventorySourceTypes.map(
                                                (inventorySourceType: InventorySourceType) => (
                                                    <FormControlLabel
                                                        key={inventorySourceType.code}
                                                        control={
                                                            <Checkbox
                                                                checked={searchCriteria.inventorySourceTypes.includes(
                                                                    inventorySourceType.code
                                                                )}
                                                            />
                                                        }
                                                        label={inventorySourceType.name}
                                                        value={inventorySourceType.code}
                                                    />
                                                )
                                            )}
                                    </FormGroup>
                                </CardContent>
                            </Card>

                            <InventorySourceDataGridCard
                                rows={filteredSelections}
                                selectedRows={selectedItems}
                                onChangeSelections={handleChangeSelections}
                                searchCriteria={searchCriteria}
                                changeSearchCriteria={changeSearchCriteria}
                                filterModel={filterModel}
                                onFilterModelChange={(newFilterModel: GridFilterModel) =>
                                    setFilterModel(newFilterModel)
                                }
                            />
                        </Row>
                    </Box>
                </Box>

                <StickyBottomAppBar>
                    <Row justifyContent="flex-end" gap={1}>
                        <CDButton onClick={() => closeDrawer([], 'cancel')}>Cancel</CDButton>
                        <CDDefaultButton
                            onClick={() => {
                                if (isDirty) {
                                    closeDrawer(selectedItems, 'save');
                                } else {
                                    closeDrawer([], 'cancel');
                                }

                                setIsDirty(false);
                            }}
                            color="primary"
                        >
                            Save
                        </CDDefaultButton>
                    </Row>
                </StickyBottomAppBar>
            </Column>
        </Drawer>
    );
}
