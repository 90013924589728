import { useCallback, useEffect, useState } from 'react';
import { Box, Drawer, DrawerProps, IconButton, Stack, Typography } from '@mui/material';
import { Card, CardHeader } from '../../pages/campaign/partials/CampaignWizard/CampaignWizardStyles';
import { ClearOutlined as ClearOutlinedIcon } from '@mui/icons-material';

import { AudienceSource, AudienceSourceCriteria, AudienceSourceFee } from '../../types/AudienceSource';
import { StickyBottomAppBar } from '../../components/AppBar';
import { useAudienceSourceCategories } from '../../hooks/useAudienceSourceCategories';
import { useDataGridSelection } from '../../hooks/useDataGridSelection';
import CDButton, { CDDefaultButton } from '../CDButton';
import Column from '../Column';
// import AudienceDataGridCard from './AudienceSourceDataGridCard';
import AudienceSourceRowDataGridCard from './AudienceSourceRowDataGridCard';
import AudienceSourceSelectionDataGridCard from './AudienceSourceSelectionDataGridCard';
import AudienceSourceFilter from './AudienceSourceFilter';
import Row from '../Row';

export type AudienceSourceDrawerProps = {
    mediaType?: string;

    // Use this hook to provide actual data
    // '../../hooks/useAudienceSources'
    selections: AudienceSource[];
    fetchSelections?: () => void;
    hydrateSelections?: (selections: AudienceSource[]) => AudienceSource[];

    items?: AudienceSource[];
    onChange?: (items: AudienceSource[]) => void;
    onClose(items: AudienceSource[], reason: 'cancel' | 'save'): void;
} & Omit<DrawerProps, 'onChange' | 'onClose'>;

export default function AudienceSourceDrawer(props: AudienceSourceDrawerProps) {
    const { mediaType, onChange, onClose, items, selections, fetchSelections, hydrateSelections, ...drawerProps } =
        props;

    // Dictionary of Audience Source Categories
    // resolved by using the "selections" property
    // of list of Audience Sources.
    const $audienceSourceCategories = useAudienceSourceCategories();

    const toGridRowId = (row: AudienceSource) => row.audienceId as any;

    const $rows = useDataGridSelection<AudienceSource>({
        getRowId: toGridRowId,
    });

    const $selections = useDataGridSelection<AudienceSource>({
        getRowId: toGridRowId,
    });

    const [searchCriteria, setSearchCriteria] = useState<AudienceSourceCriteria>({
        mediaType: '',
        searchText: '',
        categories: [],
    });

    const applySearchCriteriaInRows = useCallback(
        (newSearchCriteria: AudienceSourceCriteria) => {
            const newRows: AudienceSource[] = selections.filter((row: AudienceSource) => {
                const isRowSelected: boolean = $selections.rows.some(
                    (selection: AudienceSource) => toGridRowId(row) === toGridRowId(selection)
                );

                if (isRowSelected) {
                    return false; // Skip rows that are already selected.
                }

                if (newSearchCriteria?.categories?.length) {
                    let categoryPair: string = row.category;

                    if (row.subCategory) {
                        categoryPair = `${categoryPair}|${row.subCategory}`;
                    }

                    if (!newSearchCriteria.categories.includes(categoryPair)) {
                        return false;
                    }
                }

                if (newSearchCriteria?.searchText?.length) {
                    if (row.audienceName.toLowerCase().indexOf(newSearchCriteria.searchText.toLowerCase()) <= -1) {
                        return false;
                    }
                }

                if (newSearchCriteria?.costType?.length) {
                    const fee: AudienceSourceFee | undefined = row.fees.find((fee: AudienceSourceFee) => {
                        if (searchCriteria.mediaType && searchCriteria.mediaType.toLowerCase()) {
                            switch (searchCriteria.mediaType) {
                                case 'Display':
                                    return ['WEB'].includes(fee.impressionSupplyType);

                                case 'Video':
                                    return ['OLV', 'STV'].includes(fee.impressionSupplyType);

                                default:
                                    return (
                                        searchCriteria.mediaType.toLowerCase() ===
                                        fee.impressionSupplyType.toLowerCase()
                                    );
                            }
                        }
                        return false;
                    });

                    switch (newSearchCriteria.costType) {
                        case 'Free':
                            if (fee && fee.amount > 0 && fee.scale > 0) {
                                return false;
                            }
                            break;

                        case 'Paid':
                            if (fee && fee.amount <= 0 && fee.scale <= 0) {
                                return false;
                            }
                            break;
                    }
                }

                return true; // Include rows that pass all checks.
            });

            $rows.setRows(newRows);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selections, $selections.rows]
    );

    useEffect(() => {
        // const newRows: AudienceSource[] = selections.filter((row: AudienceSource) => {
        //     return (
        //         $selections.rows.find((selection: AudienceSource) => toGridRowId(row) === toGridRowId(selection)) ===
        //         undefined
        //     );
        // });
        // $rows.setRows(newRows);

        applySearchCriteriaInRows(searchCriteria);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selections, $selections.rows, searchCriteria]);

    useEffect(() => {
        $selections.setRows(items ?? []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    function changeSearchCriteria(searchCriteria: AudienceSourceCriteria) {
        setSearchCriteria({ ...searchCriteria });
    }

    useEffect(() => {
        $audienceSourceCategories.setAudienceSourceCategories(
            selections ? $audienceSourceCategories.identifyAudienceSourceCategories(selections) : []
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selections]);

    useEffect(() => {
        if (fetchSelections) {
            fetchSelections();
        }
    }, [fetchSelections]);

    useEffect(() => {
        changeSearchCriteria({
            ...searchCriteria,
            mediaType: mediaType,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaType]);

    useEffect(() => {
        // Make sure we have the list of audience sources
        // so we will have the audience source names
        // to display for selected audience sources.

        if (hydrateSelections) {
            if (selections.length > 0 && items?.length) {
                const hydratedSelectedItems = hydrateSelections(items);

                if (onChange) {
                    onChange(hydratedSelectedItems);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selections]);

    return (
        <Drawer
            anchor="right"
            onClose={() => onClose([], 'cancel')}
            {...drawerProps}
            sx={{
                width: 4 / 5,
                flexShrink: 0,
                zIndex: (theme) => theme.zIndex.drawer + 1,

                '& .MuiDrawer-paper': {
                    width: 4 / 5,
                    boxSizing: 'border-box',
                },
            }}
        >
            <Column
                justifyContent="space-between"
                sx={{
                    minHeight: '480px',
                    height: '100%',
                }}
            >
                <Box>
                    <Box
                        component="header"
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            paddingX: 3,
                            paddingY: 2,
                        }}
                    >
                        <Row justifyContent="space-between" alignItems="center" spacing={2}>
                            <Stack spacing={1}>
                                <Typography variant="h6">Create Custom Audience</Typography>
                            </Stack>

                            <IconButton onClick={() => onClose([], 'cancel')} edge="end">
                                <ClearOutlinedIcon fontSize="medium" color="action" />
                            </IconButton>
                        </Row>
                    </Box>

                    <Box sx={{ padding: 3 }}>
                        <Row gap={3}>
                            <Card variant="outlined" sx={{ width: 5 / 12, minHeight: '65vh' }}>
                                <CardHeader
                                    title={
                                        <Row spacing={0.5} alignItems="center">
                                            <Typography variant="subtitle2" color="text.primary">
                                                Audience Category
                                            </Typography>
                                        </Row>
                                    }
                                />
                                <Box>
                                    <AudienceSourceFilter
                                        audienceSourceCategories={$audienceSourceCategories.audienceSourceCategories}
                                        searchCriteria={searchCriteria}
                                        changeSearchCriteria={(newSearchCriteria: AudienceSourceCriteria) => {
                                            changeSearchCriteria(newSearchCriteria);

                                            applySearchCriteriaInRows(newSearchCriteria);
                                        }}
                                    />
                                </Box>
                            </Card>

                            <Column>
                                <AudienceSourceRowDataGridCard
                                    searchCriteria={searchCriteria}
                                    changeSearchCriteria={changeSearchCriteria}
                                    rows={$rows.rows}
                                    selections={$rows.selections}
                                    onAttachChange={(selection: AudienceSource) => {
                                        $selections.attach(selection);
                                    }}
                                    onAttachManyChange={(selections: AudienceSource[]) => {
                                        $selections.attachMany(selections);
                                    }}
                                    dataGridProps={{
                                        getRowId: $rows.getRowId,
                                        checkboxSelection: true,
                                        rowSelectionModel: $rows.rowSelectionModel,
                                        onRowSelectionModelChange: $rows.handleRowSelectionModelChange,
                                        paginationModel: $rows.paginationModel,
                                        onPaginationModelChange: $rows.handlePaginationModelChange,
                                    }}
                                />

                                <AudienceSourceSelectionDataGridCard
                                    searchCriteria={searchCriteria}
                                    rows={$selections.rows}
                                    selections={$selections.selections}
                                    onDetachChange={(selection: AudienceSource) => {
                                        $selections.detach(selection);
                                    }}
                                    onDetachManyChange={(selections: AudienceSource[]) => {
                                        $selections.detachMany(selections);
                                    }}
                                    dataGridProps={{
                                        getRowId: $selections.getRowId,
                                        checkboxSelection: true,
                                        rowSelectionModel: $selections.rowSelectionModel,
                                        onRowSelectionModelChange: $selections.handleRowSelectionModelChange,
                                        paginationModel: $selections.paginationModel,
                                        onPaginationModelChange: $selections.handlePaginationModelChange,
                                    }}
                                />
                            </Column>
                        </Row>
                    </Box>
                </Box>

                <StickyBottomAppBar>
                    <Row justifyContent="flex-end" gap={1}>
                        <CDButton onClick={() => onClose([], 'cancel')}>Cancel</CDButton>
                        <CDDefaultButton
                            onClick={() => {
                                onClose($selections.rows, 'save');
                            }}
                            color="primary"
                        >
                            Save
                        </CDDefaultButton>
                    </Row>
                </StickyBottomAppBar>
            </Column>
        </Drawer>
    );
}
