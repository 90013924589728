import { useEffect, useState } from 'react';
import { Box, List } from '@mui/material';
import { useCampaignWizardContext } from '../../../../hooks/useCampaignWizard';
import { ListItemButton, ListItemLabel } from './CampaignWizardStyles';

type CampaignWizardGeographyStepNavProps = {
    // step: number;
    onStep?: (step: number) => void;
};

export default function CampaignWizardGeographyStepNav(props: CampaignWizardGeographyStepNavProps) {
    const { onStep } = props;
    const { hasError } = useCampaignWizardContext();
    const [selectedIndex, setSelectedIndex] = useState(-1);

    const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
        setSelectedIndex(index);
    };

    useEffect(() => {
        if (onStep) {
            onStep(selectedIndex);
        }
    }, [onStep, selectedIndex]);

    const hasErrorSection = (section: string): boolean => {
        switch (section) {
            case 'Location':
                return hasError('adGroups.*.adGroupLocations');

            case 'Audience':
                return hasError('adGroups.*.adGroupAudienceSources');
        }

        return false;
    };

    return (
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <List component="nav" aria-label="secondary mailbox folder">
                <ListItemButton selected={selectedIndex === 3} onClick={(event) => handleListItemClick(event, 3)}>
                    <ListItemLabel error={hasErrorSection('Audience')} primary="Audience" />
                </ListItemButton>
                {/*<ListItemButton selected={selectedIndex === 2} onClick={(event) => handleListItemClick(event, 2)}>
                    <ListItemText primary="Device" />
                </ListItemButton>*/}
                <ListItemButton selected={selectedIndex === 1} onClick={(event) => handleListItemClick(event, 1)}>
                    <ListItemLabel error={hasErrorSection('Location')} primary="Location" />
                </ListItemButton>
            </List>
        </Box>
    );
}
