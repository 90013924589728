import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Box, FormControl, FormHelperText, Stack, Typography } from '@mui/material';
import { Card, CardContent, CardHeader, FormLabel } from './CampaignWizardStyles';

import { CampaignWizardStepProps } from './CampaignWizard';
import { AudienceSource } from '../../../../types/AudienceSource';
import { toCampaignAdGroupAudienceSources } from '../../../../hooks/useAudienceSources';
import { useCampaignWizardContext } from '../../../../hooks/useCampaignWizard';
import AudienceSourceField from '../../../../components/Audience/AudienceSourceField';
import CampaignAudienceTypeRadioGroup from '../CampaignAudienceTypeRadioGroup';
import Column from '../../../../components/Column';
import Row from '../../../../components/Row';

type CampaignTargetingAudienceCardProps = {} & CampaignWizardStepProps;

export default function CampaignTargetingAudienceCard(props: CampaignTargetingAudienceCardProps) {
    const { campaign, onChange } = props;

    const $campaignWizard = useCampaignWizardContext();
    const { hasError } = $campaignWizard;

    const { $audienceSources, $loader } = $campaignWizard;
    const {
        audienceSources: selections = [],
        fetchAudienceSources: fetchSelections = () => {},
        isFetchingAudienceSources: isFetchingSelections = false,
        hydrateAudienceSources: hydrateSelections = (selections: AudienceSource[]) => [],
    } = $audienceSources || {};

    const [audienceSources, setAudienceSources] = useState<AudienceSource[]>([]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.name) {
            props.onChange({
                [event.target.name]: event.target.value,
            });
        }
    };

    useEffect(() => {
        if ($loader) {
            if (isFetchingSelections) {
                $loader.loading('fetchAudienceSources');
            } else {
                $loader.loaded('fetchAudienceSources');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetchingSelections]);

    useEffect(() => {
        if (campaign?.adGroups?.length) {
            if ($audienceSources) {
                setAudienceSources($audienceSources.getCampaignAudienceSources(campaign));
            }
        } else {
            setAudienceSources([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign.adGroups]);

    const updateAudienceSources = useCallback(
        (newAudienceSources: AudienceSource[]) => {
            setAudienceSources(newAudienceSources);

            if (onChange) {
                if (campaign?.adGroups?.length) {
                    campaign.adGroups[0] = {
                        ...campaign.adGroups[0],
                        adGroupAudienceSources: toCampaignAdGroupAudienceSources(
                            newAudienceSources,
                            campaign.adGroups[0].adGroupAudienceSources ?? []
                        ),
                    };
                } else {
                    campaign.adGroups.push({
                        adGroupAudienceSources: toCampaignAdGroupAudienceSources(newAudienceSources),
                    });
                }

                onChange({
                    adGroups: campaign.adGroups,
                });
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [onChange, campaign]
    );

    const handleAudienceSourceChange = (newAudienceSources: AudienceSource[]): void => {
        updateAudienceSources(newAudienceSources);
    };

    return (
        <Card id="campaign-wizard-targeting-audience" variant="outlined">
            <CardHeader
                title={
                    <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="body1" color="text.primary">
                            Audience
                        </Typography>
                    </Stack>
                }
            />
            <CardContent>
                <Row>
                    <FormControl fullWidth error={hasError('audienceType')}>
                        <Column gap={3}>
                            <FormLabel required={true}>Audience Categories and Segments</FormLabel>

                            <CampaignAudienceTypeRadioGroup
                                name="audienceType"
                                value={campaign.audienceType ?? ''}
                                onChange={handleChange}
                            />

                            {hasError('audienceType') && (
                                <FormHelperText error={true}>
                                    Campaign Audience Type selection is required
                                </FormHelperText>
                            )}

                            {campaign.audienceType === 'CUSTOM' && (
                                <>
                                    <Box>
                                        <AudienceSourceField
                                            mediaType={campaign.mediaType}
                                            selections={selections}
                                            fetchSelections={fetchSelections}
                                            hydrateSelections={hydrateSelections}
                                            onChange={handleAudienceSourceChange}
                                            items={audienceSources}
                                        />
                                    </Box>

                                    {hasError('adGroups.*.adGroupAudienceSources') && (
                                        <FormHelperText error={true} sx={{ ml: 7 }}>
                                            Custom Audience(s) creation is required. Please add at least one audience.
                                        </FormHelperText>
                                    )}
                                </>
                            )}
                        </Column>
                    </FormControl>
                </Row>
            </CardContent>
        </Card>
    );
}
