import {
    GridFilterInputMultipleValue,
    GridFilterItem,
    GridFilterModel,
    GridFilterOperator,
    GridLogicOperator,
    getGridStringOperators,
} from '@mui/x-data-grid-pro';

function filterGridRows(rows: any[], filterModel: GridFilterModel) {
    const getGridFilterOperator = (operator: string): GridFilterOperator | undefined => {
        return getGridAllOperators().find((filterOperator: GridFilterOperator) => filterOperator.value === operator);
    };

    return [
        ...rows.filter((row: any) => {
            let matched: boolean = true;

            if (filterModel.items.length) {
                matched = filterModel.items.some((filterItem: GridFilterItem) => {
                    const filterOperator: GridFilterOperator | undefined = getGridFilterOperator(filterItem.operator);

                    if (filterOperator) {
                        const filter: any = filterOperator.getApplyFilterFn(filterItem, { field: filterItem.field });

                        if (filterModel.logicOperator === GridLogicOperator.And && matched === false) {
                            return matched;
                        }

                        if (filter) {
                            matched = filter(row?.[filterItem.field] ?? null);
                        }

                        return matched;
                    }

                    return true;
                });
            }

            return matched;
        }),
    ];
}

function getGridCustomOperators(): GridFilterOperator[] {
    const customOperators: GridFilterOperator[] = [
        {
            label: 'is any of contains',
            value: 'isAnyOfContains',
            getApplyFilterFn: (filterItem: GridFilterItem) => {
                if (!filterItem.value || !Array.isArray(filterItem.value)) {
                    return null; // null
                }
                const regexes = filterItem.value.map((value) => new RegExp(value, 'i'));
                return (params: string) =>
                    regexes.some((regex: any) => {
                        return regex.test(params);
                    });
            },
            InputComponent: GridFilterInputMultipleValue,
        },
    ];

    return customOperators;
}

function getGridAllOperators(): GridFilterOperator[] {
    return [...getGridStringOperators(), ...getGridCustomOperators()];
}

export { filterGridRows, getGridCustomOperators, getGridAllOperators };
