import { useCallback, useEffect, useState } from 'react';
import { Box, Drawer, DrawerProps, IconButton, Stack, Typography } from '@mui/material';
import { Card, CardContent } from '../../pages/campaign/partials/CampaignWizard/CampaignWizardStyles';
import { ClearOutlined as ClearOutlinedIcon } from '@mui/icons-material';

import { Location } from '../../types/Location';
import { LocationSourceCriteria } from '../../types/LocationSource';
import { ZipCode } from '../../types/Location';
import { StickyBottomAppBar } from '../../components/AppBar';
import { useDataGridSelection } from '../../hooks/useDataGridSelection';
import CDButton, { CDDefaultButton } from '../CDButton';
import Column from '../Column';
import Row from '../Row';
import Map from '../Location/LocationMap';
import LocationRadiusField from '../Location/LocationRadiusField';
import LocationSourceList from '../Location/LocationSourceList';

export type LocationSourceDrawerProps = {
    mediaType?: string;

    // Use this hook to provide actual data
    // '../../hooks/useLocations'
    selections: Location[];
    fetchSelections?: () => void;
    hydrateSelections?: (selections: Location[]) => Location[];

    items?: Location[];
    onChange?: (items: Location[]) => void;
    onClose(items: Location[], reason: 'cancel' | 'save'): void;

    disableRadius?: boolean;
    radius: number;
    onRadiusChange?: (newRadius: number) => void;
    mapCenter?: string;
    onAttach?: (newLocations: Location[]) => void;
    onDetach?: (newLocations: Location[]) => void;
} & Omit<DrawerProps, 'onChange' | 'onClose'>;

export default function LocationSourceDrawer(props: LocationSourceDrawerProps) {
    const {
        mediaType,
        onAttach,
        onDetach,
        onChange,
        onClose,
        items,
        disableRadius = false,
        radius: defaultRadius = 30,
        onRadiusChange,
        mapCenter,
        selections,
        fetchSelections,
        hydrateSelections,
        ...drawerProps
    } = props;

    const toGridRowId = (row: Location) => row.id as any;

    const $rows = useDataGridSelection<Location>({
        getRowId: toGridRowId,
    });

    const $selections = useDataGridSelection<Location>({
        getRowId: toGridRowId,
    });

    const [searchCriteria, setSearchCriteria] = useState<LocationSourceCriteria>({
        searchText: '',
    });

    const [radius, setRadius] = useState<number>(defaultRadius);

    const applySearchCriteriaInRows = useCallback(
        (newSearchCriteria: LocationSourceCriteria) => {
            const newRows: Location[] = selections.filter((row: Location) => {
                const isRowSelected: boolean = $selections.rows.some(
                    (selection: Location) => toGridRowId(row) === toGridRowId(selection)
                );

                if (isRowSelected) {
                    return false; // Skip rows that are already selected.
                }

                return true; // Include rows that pass all checks.
            });

            $rows.setRows(newRows);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selections, $selections.rows]
    );

    useEffect(() => {
        // const newRows: Location[] = selections.filter((row: Location) => {
        //     return (
        //         $selections.rows.find((selection: Location) => toGridRowId(row) === toGridRowId(selection)) ===
        //         undefined
        //     );
        // });
        // $rows.setRows(newRows);

        applySearchCriteriaInRows(searchCriteria);
    }, [applySearchCriteriaInRows, searchCriteria]);

    useEffect(() => {
        $selections.setRows(items ?? []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    function changeSearchCriteria(searchCriteria: LocationSourceCriteria) {
        setSearchCriteria({ ...searchCriteria });
    }

    const handleAttach = useCallback(
        (newLocations: Location[]): void => {
            if (onAttach) {
                onAttach(newLocations);
            }

            $selections.attachMany(newLocations);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onAttach]
    );

    const handleDetach = useCallback(
        (newLocations: Location[]): void => {
            if (onDetach) {
                onDetach(newLocations);
            }

            $selections.detachMany(newLocations);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onDetach]
    );

    useEffect(() => {
        if (fetchSelections) {
            fetchSelections();
        }
    }, [fetchSelections]);

    useEffect(() => {
        changeSearchCriteria({
            ...searchCriteria,
            mediaType: mediaType,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaType]);

    useEffect(() => {
        // Make sure we have the list of audience sources
        // so we will have the audience source names
        // to display for selected audience sources.

        if (hydrateSelections) {
            if (selections.length > 0 && items?.length) {
                const hydratedSelectedItems = hydrateSelections(items);

                if (onChange) {
                    onChange(hydratedSelectedItems);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selections]);

    function zipCodesLoaded(results: ZipCode[]) {
        // const zipCodeString = results.map(({ code }) => code).join(',');
        // const population = results.reduce((acc, r) => acc + r.population, 0);
        // onChange({
        //     zipcodes: zipCodeString,
        //     population: Math.round(population / 1000) * 1000,
        // });
    }

    function zipCodeClick(zip: ZipCode) {
        // let zips = campaign.zipcodes?.split(',') || [];
        // let population = campaign.population ? campaign.population : 0;
        // const index = zips.indexOf(zip.code);
        // if (index !== -1) {
        //     zips = [...zips.slice(0, index), ...zips.slice(index + 1)];
        //     population -= zip.population;
        // } else {
        //     zips = [...zips, zip.code];
        //     population += zip.population;
        // }
        // onChange({
        //     zipcodes: zips.join(),
        //     population: population,
        // });
    }

    return (
        <Drawer
            anchor="right"
            onClose={() => onClose([], 'cancel')}
            {...drawerProps}
            sx={{
                width: 4 / 5,
                flexShrink: 0,
                zIndex: (theme) => theme.zIndex.drawer + 1,

                '& .MuiDrawer-paper': {
                    width: 4 / 5,
                    boxSizing: 'border-box',
                },
            }}
        >
            <Column
                justifyContent="space-between"
                sx={{
                    minHeight: '480px',
                    height: '100%',
                }}
            >
                <Box>
                    <Box
                        component="header"
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            paddingX: 3,
                            paddingY: 2,
                        }}
                    >
                        <Row justifyContent="space-between" alignItems="center" spacing={2}>
                            <Stack spacing={1}>
                                <Typography variant="h6">Select Locations</Typography>
                            </Stack>

                            <IconButton onClick={() => onClose([], 'cancel')} edge="end">
                                <ClearOutlinedIcon fontSize="medium" color="action" />
                            </IconButton>
                        </Row>
                    </Box>

                    <Box sx={{ padding: 3 }}>
                        <Row gap={3}>
                            <Card variant="outlined" sx={{ width: 6 / 12, minHeight: '80vh' }}>
                                <Box>
                                    <LocationSourceList
                                        loading={false}
                                        items={$selections.rows}
                                        selections={selections}
                                        onAttach={handleAttach}
                                        onDetach={handleDetach}
                                        gridProps={{
                                            sx: {
                                                height: '80vh',
                                            },
                                        }}
                                    />
                                </Box>
                            </Card>
                            <Card variant="outlined" sx={{ width: 6 / 12, minHeight: '80vh' }}>
                                <CardContent sx={{ p: 2, height: '100%' }}>
                                    <Column gap={2} sx={{ height: '100%' }}>
                                        <LocationRadiusField
                                            radius={radius}
                                            disabled={disableRadius}
                                            onChange={(newRadius: number): void => {
                                                setRadius(newRadius);

                                                if (onRadiusChange) {
                                                    onRadiusChange(newRadius);
                                                }
                                            }}
                                        />

                                        <Box
                                            sx={{
                                                borderRadius: (theme) => `${theme.shape.borderRadius}px`,
                                                height: '100%',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <Map
                                                locations={$selections.rows}
                                                radius={radius ? radius : 30}
                                                onZipClick={zipCodeClick}
                                                onZipsLoaded={zipCodesLoaded}
                                                zipCode={mapCenter as string}
                                                zipCodes={''}
                                            />
                                        </Box>
                                    </Column>
                                </CardContent>
                            </Card>
                        </Row>
                    </Box>
                </Box>

                <StickyBottomAppBar>
                    <Row justifyContent="flex-end" gap={1}>
                        <CDButton onClick={() => onClose([], 'cancel')}>Cancel</CDButton>
                        <CDDefaultButton
                            onClick={() => {
                                onClose($selections.rows, 'save');
                            }}
                            color="primary"
                        >
                            Save
                        </CDDefaultButton>
                    </Row>
                </StickyBottomAppBar>
            </Column>
        </Drawer>
    );
}
