import { ChangeEvent } from 'react';
import { FormControl, Grid, Slider, SliderProps } from '@mui/material';
import { CDTextField, CDTextFieldProps } from '../CDTextField';

export type LocationRadiusField = {
    radius: number;
    stepRadius?: number;
    minRadius?: number;
    maxRadius?: number;
    onChange?: (radius: number) => void;

    name?: string;
    disabled?: boolean;

    textFieldProps?: CDTextFieldProps;
    sliderProps?: SliderProps;
};

export default function LocationRadiusField(props: LocationRadiusField) {
    const { radius, onChange } = props;

    const { stepRadius = 5, minRadius = 10, maxRadius = 75 } = props;

    const { name, disabled = false } = props;

    const { textFieldProps, sliderProps } = props;

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (onChange) {
            onChange(parseInt(event.target.value));
        }
    };

    const handleSliderChange = (event: any, newValue: number | number[]): void => {
        if (onChange) {
            onChange(parseInt(event.target.value));
        }
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={3}>
                <FormControl fullWidth size="small">
                    <CDTextField
                        variant="outlined"
                        label="Radius (mi)"
                        // onBlur={handleRecenterLocation}
                        {...textFieldProps}
                        name={name ?? 'radius'}
                        type="number"
                        value={radius ?? ''}
                        onChange={handleChange}
                        disabled={disabled}
                        InputProps={{
                            inputProps: { min: minRadius, max: maxRadius },
                        }}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={9}>
                <FormControl fullWidth size="small">
                    <Slider
                        getAriaValueText={(value: number) => `${value}`}
                        marks={[
                            { value: minRadius, label: minRadius as any as string },
                            { value: maxRadius, label: maxRadius as any as string },
                        ]}
                        {...sliderProps}
                        name={name ?? 'radius'}
                        defaultValue={minRadius}
                        value={radius ?? minRadius}
                        step={stepRadius}
                        min={minRadius}
                        max={maxRadius}
                        valueLabelDisplay="on"
                        onChange={handleSliderChange}
                        disabled={disabled}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
}
