import { Typography } from '@mui/material';
import { Campaign } from '../../types/Campaign';
import Column from '../Column';
import Utils from '../Utils';

export interface CampaignBudgetCapSummaryProps {
    campaign: Campaign;
}

export default function CampaignBudgetCapSummary(props: CampaignBudgetCapSummaryProps) {
    const { campaign } = props;

    return (
        <Column gap={0}>
            {campaign.budgetCapType !== 'CUSTOM' && (
                <Typography>{Utils.convertCase(campaign.budgetCapType)}</Typography>
            )}
            {campaign.budgetCapType === 'CUSTOM' && (
                <>
                    <Typography component="span">
                        {Utils.convertCase(campaign.budgetCapRecurrenceTimePeriod)}
                    </Typography>
                    {', '}
                    <Typography component="span" variant="body2">
                        {Utils.formatCurrency(campaign.budgetCapAmount, 2)}
                    </Typography>
                </>
            )}
        </Column>
    );
}
